import packageInfo from "../../package.json";

const Config = {
  TEST: true,
  DEBUG: false,
  imageUrlBase: "https://partner.enniakarok.hu/data/img/",
  mobileMaxWidth: 1000,
  version: packageInfo.version,
  APIBaseURL: "https://backend.enniakarok.hu/partner",
  imageUploadURL: "https://partner.enniakarok.hu/php/upload.php",
  frontendURL: "https://www.blundee.com",
  wsURL: "wss://backend.enniakarok.hu/ws",
  notify_order_not_confirmed_minutes_preorder: 30,
  notify_order_not_confirmed_minutes: 5,
  adminDomains: ["blundee.com", "enniakarok.hu"],
  googleApiKey: "AIzaSyBO6kU3ezNvQHDwIjpUN_Li3EMLUTq9HGk",
  configurations: [],
};

if (Config.TEST) {
  Config.imageUrlBase = "https://partner.test.enniakarok.hu/data/img/";
  Config.APIBaseURL = "https://backend.test.enniakarok.hu/partner";
  Config.imageUploadURL = "https://partner.test.enniakarok.hu/php/upload.php";
  Config.frontendURL = "https://test.enniakarok.hu";
  Config.wsURL = "wss://backend.test.enniakarok.hu/ws";
  if (Config.DEBUG) {
    Config.APIBaseURL = "http://localhost:3332/partner";
    Config.imageUploadURL = "http://localhost:80/partner";
    Config.frontendURL = "https://localhost:3000";
    Config.wsURL = "ws://localhost:8000";
  }
} else if (Config.DEBUG) {
  Config.imageUrlBase = "https://partner.test.enniakarok.hu/data/img/";
  Config.APIBaseURL = "http://localhost:3333/partner";
  Config.imageUploadURL = "http://localhost:80/php/upload.php";
  Config.frontendURL = "https://localhost:3000";
  Config.wsURL = "ws://localhost:8000";
}

Config.productImageUrlBase = Config.imageUrlBase + "product/";
Config.partnerImageUrlBase = Config.imageUrlBase + "partner/";
Config.qrImageUrlBase = Config.imageUrlBase + "qr/";


Config.getBoolean = function (name: string): boolean {
  let v: any = Config.getValue(name);
  if (!v)
    return undefined;
  return v === true || v === 1 || v === "1" || v === "y" || v === "Y"
}

Config.getNumber = function (name: string): number {
  let v: any = Config.getValue(name);
  if (!v)
    return undefined;
  return parseFloat(v);
}

Config.getString = function (name: string): string {
  let v: any = Config.getValue(name);
  if (!v)
    return undefined;
  return "" + v;
}

Config.getValue = function (name: string): any {
  for (let configuration of Config.configurations) {
    if (configuration.key === name)
      return configuration.value;
  }
  return undefined;
}

export default Config;

export const ConfigKeys = {
  enable_table_reservation: "enable_table_reservation",
  google_forms_url: "google_forms_url",
  enable_homepage_google_forms: "enable_homepage_google_forms",
  homepage_google_forms_details: "homepage_google_forms_details",
  homepage_google_forms_title: "homepage_google_forms_title",
  sales_offers: "sales_offers",
  sales_phone: "sales_phone",
  sales_mail: "sales_mail",
  sales_video: "sales_video",
  sales_video_title: "sales_video_title",
  sales_video_yt_id: "sales_video_yt_id",
  sales_pos: "sales_pos",
  sales_structure: "sales_structure",
  sales_main_title: "sales_main_title",
  what_is_blundee_page_title: "what_is_blundee_page_title",
  what_is_blundee_page_structure: "what_is_blundee_page_structure",
  order_not_available_text: "order_not_available_text",
  partner_footer_phone: "partner_footer_phone",
  partner_footer_mail: "partner_footer_mail",
  partner_contract_company: "partner_contract_company",
  partner_contract_vat: "partner_contract_vat",
  partner_contract_official_id: "partner_contract_official_id",
  partner_contract_bank_iban: "partner_contract_bank_iban",
  partner_contract_email: "partner_contract_email",
  partner_contract_contact: "partner_contract_contact",
  partner_contract_address: "partner_contract_address",
  partner_contract_representative: "partner_contract_representative",
}
