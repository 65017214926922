// noinspection DuplicatedCode

import React, { Component } from "react";
import styled, { css, keyframes } from "styled-components";
import type { PutPaymentType } from "../../utils/api/OrdersAPI";
import { OrdersAPI } from "../../utils/api/OrdersAPI";
import { toast } from "react-toastify";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";
import DateTimePickerDefault from "react-datetime-picker";
import Loader from "react-loader-spinner";
import type { OrderProductSorted } from "../../model/Order";
import { Order, OrderPayment, OrderProduct, OrderState, TableReservation } from "../../model/Order";
import { MultiSelect as MultiSelectOrg } from "react-multi-select-component";
import Language, { Names } from "../../utils/Language";
import { Input } from "../../pages/LoginPage";
import { Product, Version } from "../../model/Product";
import { Label } from "../modals/ContractsDetailsModal";
import { Button as ButtonOrg } from "../FormComponents";
import ErrorMessage from "../../utils/api/ErrorMessages";
import OrderComponent from "./OrderComponent";
import { ShopProfile } from "../../model/ShopProfile";
import { ShippingMethods } from "../../model/ShippingPrice";
import Layout from "../../model/Layout";
import { PaymentMethods } from "../../model/PaymentMethodSetting";
import { UpsellTypes } from "../../model/UpsellRule";

export const CheckBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0 5px 0 10px;
  height: 40px;
  user-select: none;

  & > input {
    margin: 0 5px 0 0;
    padding: 0;
    cursor: pointer;
  }

  & > label {
    cursor: pointer;
    margin: 0;
    font-size: 10pt;
  }
`;

const MultiSelect = styled(MultiSelectOrg)`
  margin: 0 0 0 10px;
  width: 180px;
  height: 40px;
  font-size: 10pt;
  z-index: 0;

  @media screen and (max-width: 800px) {
    width: 145px;
  }
`;

const Button = styled(ButtonOrg)`
  margin: 0 5px;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;

  audio {
    display: none;
  }
`;

export const TabBar = styled.div`
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #e9ecef;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;

  ${({ font_size }) => font_size !== undefined && css`
    font-size: ${font_size};
  `}
`;

export const pulseBgAnimation = keyframes`
  from {
    background-color: white;
    color: crimson;
  }
  to {
    background-color: #841a1e;
    color: white;
  }
`;

export const TabItem = styled.div`
  width: 50%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: crimson;
  ${({ font_size }) => font_size !== undefined && css`
    font-size: ${font_size};
  `}

  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border: 1px solid transparent;

  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;

  &:hover, &:active {
    //border: 1px solid #e9ecef;
    color: white;
    background-color: #b62e30;
    text-decoration: none;
    cursor: pointer;
  }

  ${({ selected }) => selected && css`
    background-color: #841a1e;
    color: white;
  `}

  ${({ pulse }) => pulse === true && css`
    animation: ${pulseBgAnimation} 0.4s ease-in-out infinite alternate;
  `};
`;

const OrdersWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 0 24px;
  min-height: calc(100% - 60px);

  overflow-y: scroll;
`;

// const Select = styled(Select2)`
//   width: auto;
//   min-width: 200px;
//   margin: 0 10px 0 0;
//   align-self: flex-end;
// `;

const FiltersText = styled.h1`
  font-size: 16pt;
  width: 100%;
  text-align: left;
  margin: 0;
`;

const FiltersButtons = styled.div`
  //width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  flex-grow: 2;
`;

const FiltersWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: nowrap;
  padding: 5px 2%;
`;

const DateWrapper = styled.div`
  margin: 0 5px;
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  & > span {
    margin: 0 0 3px 0;
  }

  @media screen and (max-width: 800px) {
    width: 48%;
  }
`;

const DateTimePicker = styled(DateTimePickerDefault)`
  width: 100%;
  margin-top: 0;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ProductType = {
  PRODUCT: 1,
  MENU: 2,
};

export const HourType = {
  OPENING: 1,
  DELIVERY: 2,
  ORDER_ACCEPT: 3,
  PICK_UP_ORDER_ACCEPT: 4,
};

export function DaysName(dayOfWeek: number) {
  return Language.getName(Names.DayNames, dayOfWeek - 1);
}

export function getShippingName(shippingMode: number) {
  if (shippingMode === ShippingMethods.PICKUP)
    return Language.getName(Names.TakeAway);
  else if (shippingMode === ShippingMethods.DELIVERY)
    return Language.getName(Names.Delivery);
  else if (shippingMode === ShippingMethods.AT_PLACE)
    return Language.getName(Names.AtPlace);
  else if (shippingMode === ShippingMethods.VENDOR_DELIVERY)
    return Language.getName(Names.VendorDelivery);
  return "-";
}

export default class Orders extends Component {
  state: {
    orders: Order[],
    products: Product[],
    selectedTab: number,
    selectedOrder: string,
    mounted: boolean,
    editMinDate: Date,
    editMaxDate: Date,
    selectedFilteredStatusOptions: undefined | {},
    statusOptions: [],
    loading: boolean,
    loadingFinished: boolean,
    editSearch: string,
    language: number,
    shippingModeOptions: { value: number, label: string }[],
    selectedFilteredShippingModes: { value: number, label: string }[],
    showHidden: boolean,
    now5s: Date,
    layout: number
  } = {
    orders: ContextSystem.orders.filter(o => o.partnerID === ContextSystem.selectedShop.id),
    products: ContextSystem.products,
    selectedTab: 1,
    selectedOrder: "-1",
    mounted: true,
    editMinDate: new Date(),
    editMaxDate: new Date(),
    selectedFilteredStatusOptions: undefined,
    statusOptions: [],
    loading: ContextSystem.newOrdersLoading,
    loadingFinished: ContextSystem.ordersLoadingFinished,
    editSearch: "",
    language: ContextSystem.language,
    shippingModeOptions: Orders.createShippingModeOptions(),
    selectedFilteredShippingModes: Orders.createShippingModeOptions(),
    showHidden: false,
    now5s: new Date(),
    layout: ContextSystem.layout,
  };
  eventIDs = [];

  static createShippingModeOptions() {
    return [
      { value: ShippingMethods.DELIVERY, label: Language.getName(Names.Delivery) },
      { value: ShippingMethods.PICKUP, label: Language.getName(Names.TakeAway) },
      { value: ShippingMethods.AT_PLACE, label: Language.getName(Names.AtPlace) },
      { value: ShippingMethods.VENDOR_DELIVERY, label: Language.getName(Names.VendorDelivery) },
    ];
  }

  getMonday(d) {
    d = new Date(d);
    let day = d.getDay();
    let diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  static getStatusString(status: number) {
    switch (status) {
      case OrderState.NEW:
        return Language.getName(Names.OrderStateNew);
      case OrderState.CONFIRMED:
        return Language.getName(Names.OrderStateConfirmed);
      case OrderState.DECLINED:
        return Language.getName(Names.OrderStateDeclined);
      case OrderState.DELETED:
        return Language.getName(Names.OrderStateDeleted);
      case OrderState.DONE:
        return Language.getName(Names.OrderStateDone);
      case OrderState.READY:
        return Language.getName(Names.OrderStateReady);
      case OrderState.WAITING_FOR_ONLINE_PAYMENT:
        return Language.getName(Names.WaitingForOnlinePayment);
      case OrderState.FAILED_TRANS:
        return Language.getName(Names.OrderStateFailedTrans);
      case OrderState.PREPARING:
        return Language.getName(Names.Preparing);
      case OrderState.SERVED:
        return Language.getName(Names.Served);
      default:
        return Language.getName(Names.OrderState3Dot);
    }
  }

  static getShippingMethodString(method: number) {
    switch (method) {
      case ShippingMethods.PICKUP:
        return Language.getName(Names.TakeAway);
      case ShippingMethods.DELIVERY:
        return Language.getName(Names.Delivery);
      case ShippingMethods.VENDOR_DELIVERY:
        return Language.getName(Names.VendorDelivery);
      case ShippingMethods.AT_PLACE:
        return Language.getName(Names.AtPlace);
      default:
        return Language.getName(Names.ShippingMode3Dot);
    }
  }

  static getUpsellRuleTypeString(type: number) {
    switch (type) {
      case UpsellTypes.APP_AT_END_OF_ORDER:
        return Language.getName(Names.APP_AT_END_OF_ORDER);
      case UpsellTypes.APP_WHILE_ORDERING:
        return Language.getName(Names.APP_WHILE_ORDERING);
      case UpsellTypes.APP_BEFORE_PAYMENT:
        return Language.getName(Names.APP_BEFORE_PAYMENT);
      case UpsellTypes.APP_AFTER_PAYMENT:
        return Language.getName(Names.APP_AFTER_PAYMENT);
      case UpsellTypes.POS_AT_END_OF_ORDER:
        return Language.getName(Names.POS_AT_END_OF_ORDER);
      case UpsellTypes.POS_WHILE_ORDERING:
        return Language.getName(Names.POS_WHILE_ORDERING);
      case UpsellTypes.APP_AFTER_PRODUCT_VIEW:
        return Language.getName(Names.APP_AFTER_PRODUCT_VIEW);
      default:
        return "...";
    }
  }

  static getShippingMethodVATString(method: number) {
    switch (method) {
      case ShippingMethods.PICKUP:
        return Language.getName(Names.TakeAwayVAT);
      case ShippingMethods.DELIVERY:
        return Language.getName(Names.DeliveryVAT);
      case ShippingMethods.AT_PLACE:
        return Language.getName(Names.LocalVAT);
      default:
        return Language.getName(Names.ShippingMode3Dot);
    }
  }

  filterDoneOrders() {
    ContextSystem.downloadFinishedOrders(this.state.editMinDate, this.state.editMaxDate);
  }

  export() {
    if (this.state.orders.length <= 0) {
      toast(Language.getName(Names.NoOrders));
      return;
    }

    let d = ";";
    let csvContent = "";
    csvContent += Language.getName(Names.Date) + d
      + Language.getName(Names.OrderIDText) + d
      + Language.getName(Names.ShortDailyNumber) + d
      + Language.getName(Names.ProfileIDText) + d
      + Language.getName(Names.PaymentMethod) + d
      + Language.getName(Names.ShippingMode) + d
      + Language.getName(Names.ShippingPrice) + d
      + Language.getName(Names.Colleague) + d
      + Language.getName(Names.State) + d
      + Language.getName(Names.Total)
      + "\r\n";
    let totalPrice = 0;
    for (let order of this.state.orders) {
      if (!order.lastState.finished)
        continue;

      if (!this.filterOrder(order))
        continue;

      let shopProfile: ShopProfile = undefined;
      if (order.shopProfileID > 0)
        shopProfile = ContextSystem.selectedShop.employees.find(e => e.id === order.shopProfileID);

      let shPrString: string = shopProfile
                               ? shopProfile.firstName + " " + shopProfile.lastName + ", (" + shopProfile.email + ")"
                               : "-";

      let row = "";
      row += order.date.toHunFormatMinutes() + d;
      row += order.number + d;
      row += order.dailyNumber + d;
      row += (order.profile?.id ?? "-") + d;
      row += PaymentMethods.getName(order.paymentMethod) + d;
      row += Orders.getShippingMethodString(order.shippingMethod) + d;
      row += order.shippingPrice + d;
      row += Orders.getStatusString(order.lastState.status) + d;
      row += shPrString + d;
      row += order.orderTotalPrice + d;

      totalPrice += order.orderTotalPrice;
      csvContent += row + "\r\n";
    }
    csvContent += "\r\n";
    csvContent += " ; ; ; ; ; ; ;" + Language.getName(Names.Total) + ":;" + totalPrice + "\r\n";

    //for excel
    let universalBOM = "\uFEFF";
    let encodedUri = encodeURI(universalBOM + csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", "data:text/csv;charset=UTF-8," + encodedUri);
    link.setAttribute("download", "blundee_export.csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  componentWillUnmount() {
    clearTimeout(this.autoRefreshTimeout);
    //if (this.timeOut) clearTimeout(this.timeOut);
    this.setState({ mounted: false });
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
  }

  setOrders(orders: Order[]) {
    if (!orders)
      return;

    this.setState({
      orders: orders.filter(o => o.partnerID === ContextSystem.selectedShop.id),
    });
  }

  autoRefreshTimeout;

  autoRefreshDate() {
    this.autoRefreshTimeout = setTimeout(() => {
      this.setState({ now5s: new Date() });
      this.autoRefreshDate();
    }, 1000);
  }

  componentDidMount() {
    let statusOptions = [];

    const OrderStatesDone = [OrderState.DECLINED, OrderState.DELETED, OrderState.FAILED_TRANS, OrderState.DONE];

    for (let value of OrderStatesDone) {
      statusOptions.push({ label: Orders.getStatusString(value), value });
    }

    this.autoRefreshDate();

    this.setState({ statusOptions });
    this.setState({
      orders: ContextSystem.orders,
      products: ContextSystem.products,
      loading: ContextSystem.newOrdersLoading,
      layout: ContextSystem.layout,
    });

    ContextSystem.downloadFinishedOrders(this.state.editMinDate, this.state.editMaxDate);

    //this.autoLoadNewOrders(); (not needed, WS real-time sync works)

    let id = EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({ orders, products, ordersLoading, ordersLoadingFinished, language, layout }) => {
        if (language !== undefined) {
          this.setState({
            language: language,
            shippingModeOptions: Orders.createShippingModeOptions(),
          });
        }
        if (layout !== undefined)
          this.setState({ layout: layout });
        if (ordersLoading !== undefined)
          this.setState({ loading: ordersLoading });
        if (ordersLoadingFinished !== undefined)
          this.setState({ loadingFinished: ordersLoadingFinished });
        if (orders !== undefined)
          this.setOrders(orders);
        if (products !== undefined)
          this.setState({ products });
      },
    );

    this.eventIDs.push(id);
  }

  changeTab(tab) {
    this.setState({ selectedTab: tab });
  }

  static sortByDate(date1, date2, asc): number {
    if (date1 === date2)
      return 0;
    else if (date1 > date2)
      return asc ? -1 : +1;
    else
      return asc ? +1 : -1;
  }

  hasFinishedOrders(): boolean {
    if (!this.state.orders || this.state.orders.length <= 0)
      return false;
    for (let order: Order of this.state.orders) {
      if (order.lastState.finished)
        return true;
    }
    return false;
  }

  hasNewOrders(): boolean {
    if (!this.state.orders || this.state.orders.length <= 0)
      return false;
    for (let order: Order of this.state.orders) {
      let os = order.lastState?.status;
      if (os === OrderState.NEW)
        return true;
    }
    return false;
  }

  hasConfirmedOrders(): boolean {
    if (!this.state.orders || this.state.orders.length <= 0)
      return false;

    for (let order: Order of this.state.orders) {
      let os = order.lastState?.status;
      if (os === OrderState.CONFIRMED || os === OrderState.READY || os === OrderState.PREPARING)
        return true;
    }
    return false;
  }

  static sortProducts(products: OrderProduct[]): OrderProductSorted[] {
    if (!products || products.length <= 0)
      return [];

    products.sort((p: OrderProduct) => p.productIndex);

    let productLists: OrderProduct[][] = [];
    let temp: OrderProduct[] = [];
    for (let orderProduct of products) {
      if (temp.length <= 0 || temp[0].productIndex === orderProduct.productIndex) {
        temp.push(orderProduct);
      } else {
        if (temp.length > 0) {
          productLists.push(temp);
          temp = [];
        }
        temp.push(orderProduct);
      }
    }
    if (temp.length > 0)
      productLists.push(temp);

    let final: OrderProductSorted[] = [];
    for (let orderProductList: OrderProduct[] of productLists) {
      if (orderProductList.length <= 1) {
        final.push(orderProductList[0]);
      } else {
        let menuOP: OrderProductSorted;
        for (let op: OrderProduct of orderProductList) {
          if (!op)
            continue;

          if (op.type === ProductType.MENU) {
            menuOP = op;
            break;
          }
        }
        if (menuOP) {
          menuOP.products = [];
          for (let op of orderProductList) {
            if (op.type === ProductType.PRODUCT)
              menuOP.products.push(op);
          }
          final.push(menuOP);
        } else {
          final.push(orderProductList);
        }
      }
    }

    return final;
  }

  static getProductByID(products: Product[], productID: number) {
    if (!products)
      return null;

    for (let p: Product of products) {
      if (p.id === productID)
        return p;
    }
    return null;
  }

  static getVersionByID(versions: Version[], versionID: number) {
    for (let v: Version of versions) {
      if (v.id === versionID)
        return v;
    }
    return null;
  }

  searchChanged(search: string) {
    this.setState({ editSearch: search });
  }

  filterOrder(o: Order): boolean {
    let filterByString = true; //true = let it be shown
    let filterByShippingMode = false;
    let filterByHidden = true;

    if (Order.isHidden(o)) {
      filterByHidden = this.state.showHidden;
    }

    let s = this.state.editSearch.toLowerCase();
    if (s && s.length > 0) {
      let tableReservation: TableReservation = ContextSystem.tableReservations.find(
        tr => tr.id === o.tableReservationID);
      let table: Element = tableReservation === undefined ? undefined : ContextSystem.elements.find(
        el => el.id === tableReservation.tableID);

      filterByString =
        (o.profile && (
          o.profile.lastName.toLowerCase().includes(s) ||
          o.profile.tel.includes(s) ||
          o.profile.firstName.toLowerCase().includes(s)
        )) ||
        o.dailyNumber.includes(s) ||
        o.dailyNumber.replaceAll("-", "").includes(s) ||
        o.dailyNumber.replaceAll("-", "").includes(s.replaceAll(" ", "")) ||
        o.number.includes(s) ||
        o.number.replaceAll("-", "").includes(s) ||
        o.number.replaceAll("-", "").includes(s.replaceAll(" ", "")) ||
        (
          o.tableReservationID > 0 && table && table.name.toLowerCase().includes(s)
        );
    }

    for (let selectedFilteredShippingMode of this.state.selectedFilteredShippingModes) {
      if (selectedFilteredShippingMode.value === o.shippingMethod) {
        filterByShippingMode = true;
        break;
      }
    }

    return filterByShippingMode && filterByString && filterByHidden;
  }

  openTableHistory(tableReservation: TableReservation, table: Element) {
    if (!table || !tableReservation) {
      toast(Language.getName(Names.TableNotFound));
      return;
    }

    // EventSystem.publish(EventSystem.events.open_table_history, {tableReservation, table});

    this.searchChanged(table.name);
    this.shippingModesSelected([{ value: ShippingMethods.AT_PLACE, label: Language.getName(Names.AtPlace) }]);
  }

  shippingModesSelected(s: { value: number, label: string }) {
    this.setState({ selectedFilteredShippingModes: s });
  }

  static saveNewPaymentsForOrders(orders: Order[], payments: OrderPayment[], deletePayments: OrderPayment[]) {
    let finalPayments: OrderPayment[] = [];

    //split the payments based on orders
    for (let o of orders) {
      let paid: number = 0;
      o.payments.forEach(p => paid += p.amount - p.creditedAmount);

      let amount: number = o.orderTotalPrice - paid;
      finalPayments = [];

      for (let pay of payments) {
        if (pay.amount - pay.creditedAmount <= 0)
          continue;

        let reduce: number = Math.min(amount, pay.amount - pay.creditedAmount);
        pay.amount -= reduce;
        amount -= reduce;

        if (reduce > 0) {
          finalPayments.push({
            id: -1,
            enabled: true,
            orderID: -1,
            txid: -1,
            creditedAmount: 0,
            paymentType: pay.paymentType,
            payed: true,
            amount: reduce,
            orderContactID: pay.orderContactID,
            productPayments: pay.productPayments,
            _split: pay._split,
            dateTime: pay.dateTime,
            shopID: pay.shopID
          });
        }

        if (amount <= 0)
          break;
      }

      if (finalPayments.length > 0 || deletePayments.length > 0) {
        let orderPaymentsSend: PutPaymentType[] = finalPayments.map((op: OrderPayment) => {
          return { orderNumber: o.number, orderPayment: op };
        });

        OrdersAPI.putPayments(orderPaymentsSend, deletePayments, res2 => {
          if (res2.error !== ErrorMessage.OK) {
            toast(Language.getName(Names.OrderPaymentError));
          }
        });
      }
    }

    //if there is any "extra" payments, we need to save those too
    payments = payments.filter(p => p.amount !== 0);
    if (payments.length <= 0)
      return;

    let orderPaymentsSend: PutPaymentType[] = payments.map((op: OrderPayment) => {
      return { orderNumber: orders[0].number, orderPayment: op };
    });

    OrdersAPI.putPayments(orderPaymentsSend, deletePayments,res2 => {
      if (res2.error !== ErrorMessage.OK) {
        toast(Language.getName(Names.OrderPaymentError));
      }
    });
  }

  getAllowedShippingModes(): number[] {
    let allowedTypes: number[] = [];
    if (this.state.layout === Layout.MANAGER) {
      allowedTypes.push(ShippingMethods.AT_PLACE);
      allowedTypes.push(ShippingMethods.DELIVERY);
      allowedTypes.push(ShippingMethods.VENDOR_DELIVERY);
      allowedTypes.push(ShippingMethods.PICKUP);
    } else if (this.state.layout === Layout.STANDING) {
      allowedTypes.push(ShippingMethods.DELIVERY);
      allowedTypes.push(ShippingMethods.VENDOR_DELIVERY);
      allowedTypes.push(ShippingMethods.PICKUP);
      if (ContextSystem.selectedShop.posStandShowLocalOrders)
        allowedTypes.push(ShippingMethods.AT_PLACE);
    } else if (this.state.layout === Layout.WAITER) {
      allowedTypes.push(ShippingMethods.AT_PLACE);
      if (ContextSystem.selectedShop.posWaiterShowNonLocalOrders) {
        allowedTypes.push(ShippingMethods.DELIVERY);
        allowedTypes.push(ShippingMethods.VENDOR_DELIVERY);
        allowedTypes.push(ShippingMethods.PICKUP);
      }
    }
    return allowedTypes;
  }

  render() {
    let orders = this.state.orders;

    let allowedTypes: number[] = this.getAllowedShippingModes();

    orders = orders.filter(o => allowedTypes.includes(o.shippingMethod));

    let confirmedOrders: Order[] = orders.filter(o => [OrderState.CONFIRMED, OrderState.READY, OrderState.PREPARING, OrderState.SERVED].includes(o.lastState?.status));

    let ordersForTab: Order[] = orders;
    if (this.state.selectedTab === 0)
      ordersForTab = this.state.orders.filter(o => o.lastState?.status === OrderState.NEW);
    else if (this.state.selectedTab === 1)
      ordersForTab = confirmedOrders;
    else if (this.state.selectedTab === 2)
      ordersForTab = this.state.orders.filter(o => o.lastState?.finished);

    let hiddenOrdersCount: number = ordersForTab.filter(o => Order.isHidden(o)).length;

    return (
      <Wrapper>
        <TabBar>
          <TabItem pulse={this.hasNewOrders()} selected={this.state.selectedTab === 0}
                   onClick={() => this.changeTab(0)}>
            {Language.getName(Names.NewOrders)}
          </TabItem>
          <TabItem selected={this.state.selectedTab === 1} onClick={() => this.changeTab(1)}>
            {Language.getName(Names.ConfirmedOrders)}
            {confirmedOrders.length > 0 &&
              <>{" (" + confirmedOrders.length + ")"}</>
            }
          </TabItem>
          <TabItem selected={this.state.selectedTab === 2} onClick={() => this.changeTab(2)}>
            {Language.getName(Names.DoneOrders)}
          </TabItem>
        </TabBar>
        <OrdersWrapper>
          {this.state.selectedTab === 2 && (
            <>
              <FiltersText>{Language.getName(Names.Filters)}</FiltersText>
              <FiltersWrapper>
                {/*<Select*/}
                {/*    placeholder={"Állapot (mind)"}*/}
                {/*    value={this.state.selectedFilteredStatusOptions}*/}
                {/*    onChange={e => this.setState({selectedFilteredStatusOptions: e})}*/}
                {/*    options={this.state.statusOptions}*/}
                {/*    noOptionsMessage={() => "Nincs kiválasztható állapot"}*/}
                {/*    isMulti={true}*/}
                {/*/>*/}
                <DateWrapper>
                  <span>{Language.getName(Names.StartDate)}: </span>
                  <DateTimePicker
                    onChange={(date) => {
                      this.setState({ editMinDate: date });
                    }}
                    value={this.state.editMinDate}
                    locale={"HU"}
                    disableClock
                    format={"yyyy.MM.dd."}
                  />
                </DateWrapper>
                <DateWrapper>
                  <span>{Language.getName(Names.EndDate)}: </span>
                  <DateTimePicker
                    onChange={(date) => {
                      this.setState({ editMaxDate: date });
                    }}
                    value={this.state.editMaxDate}
                    locale={"HU"}
                    disableClock
                    format={"yyyy.MM.dd."}
                  />
                </DateWrapper>
                <FiltersButtons>
                  <Button onClick={() => this.filterDoneOrders()}>{Language.getName(Names.Search)}</Button>
                  <Button onClick={() => this.export()}>{Language.getName(Names.Export)}</Button>
                </FiltersButtons>
              </FiltersWrapper>
            </>
          )}
          {(
            ((this.state.selectedTab === 0 || this.state.selectedTab === 1) && this.state.loading) ||
            (this.state.selectedTab === 2 && this.state.loadingFinished)
          ) && (
            <LoaderWrapper>
              <Loader visible={true} type="ThreeDots" color="rgb(80,80,80)"
                      height={20} width={100} />
            </LoaderWrapper>
          )}
          {(
              (this.state.selectedTab === 0 && (!this.state.loading || orders.length > 0)) ||
              (this.state.selectedTab === 1 && !this.state.loading) ||
              (this.state.selectedTab === 2 && !this.state.loadingFinished)
            )
            && (
              <>
                {
                  (!orders || orders.length <= 0 ||
                    (this.state.selectedTab === 0 && !this.hasNewOrders()) ||
                    (this.state.selectedTab === 1 && !this.hasConfirmedOrders()) ||
                    (this.state.selectedTab === 2 && !this.hasFinishedOrders())
                  ) &&
                  <h2>{Language.getName(Names.NoOrders)}</h2>
                }

                {
                  (orders && orders.length > 0 &&
                    (
                      (this.state.selectedTab === 0 && this.hasNewOrders()) ||
                      (this.state.selectedTab === 1 && this.hasConfirmedOrders()) ||
                      (this.state.selectedTab === 2 && this.hasFinishedOrders())
                    )
                  ) &&
                  <FiltersWrapper>
                    <Input
                      m_width={"145px"}
                      width={"225px"}
                      height={"40px"}
                      margin={"0"}
                      autoComplete={"off"}
                      value={this.state.editSearch}
                      type="text"
                      placeholder={Language.getName(Names.Search) + "..."}
                      onChange={(e) => this.searchChanged(e.target.value)}
                    />
                    <MultiSelect
                      options={this.state.shippingModeOptions}
                      value={this.state.selectedFilteredShippingModes}
                      onChange={(s) => this.shippingModesSelected(s)}
                      labelledBy={"Select"}
                      disableSearch
                      styles={{
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                      }}
                      overrideStrings={{
                        selectSomeItems: Language.getName(Names.FilterShippingModes),
                        allItemsAreSelected: Language.getName(Names.AllSelected),
                        selectAll: Language.getName(Names.All),
                        search: Language.getName(Names.Search),
                        clearSearch: Language.getName(Names.Clear),
                      }}
                      hasSelectAll={true}
                    />
                    <CheckBox>
                      <input
                        type={"checkbox"}
                        checked={this.state.showHidden}
                        onChange={e => this.setState({ showHidden: e.target.checked })}
                      />
                      <Label onClick={() => this.setState({ showHidden: !this.state.showHidden })}>
                        {Language.getName(Names.ShowHiddenOrders)}
                        {hiddenOrdersCount > 0 ? " (" + hiddenOrdersCount + ")" : ""}
                      </Label>
                    </CheckBox>
                  </FiltersWrapper>
                }

                {ordersForTab.map((order, i) => {
                  let orderProducts: OrderProductSorted[] = Orders.sortProducts(order.productList);
                  order.subOrders.forEach(subO => orderProducts.push(Orders.sortProducts(subO.productList)));

                  let state: OrderState = order.lastState;

                  if (this.state.selectedTab === 0 && state.status !== OrderState.WAITING_FOR_ONLINE_PAYMENT && state.status !== OrderState.NEW)
                    return <React.Fragment key={i} />;
                  if (this.state.selectedTab === 1 && state.status !== OrderState.CONFIRMED && state.status !== OrderState.PREPARING && state.status !== OrderState.READY && state.status !== OrderState.SERVED)
                    return <React.Fragment key={i} />;
                  if (this.state.selectedTab === 2 && state.status !== OrderState.DONE && state.status !== OrderState.DECLINED && state.status !== OrderState.DELETED && state.status !== OrderState.FAILED_TRANS && (order.date < this.state.editMinDate || order.date > this.state.editMaxDate))
                    return <React.Fragment key={i} />;
                  if (this.state.selectedTab === 2 && (order.date < this.state.editMinDate || order.date > this.state.editMaxDate))
                    return <React.Fragment key={i} />;

                  if (!this.filterOrder(order))
                    return <React.Fragment key={i} />;

                  return (
                    <OrderComponent showActions={true} showTotal={true} order={order} key={i}
                                    onTableClick={(res: TableReservation, table: Element) => this.openTableHistory(res,
                                      table,
                                    )}
                    />
                  );
                })}
              </>
            )}
        </OrdersWrapper>
      </Wrapper>
    );
  }
}
