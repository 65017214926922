import React, { Component } from "react";
import Modal from "../Modal";
import EventSystem from "../../../utils/EventSystem";
import Language, { Names } from "../../../utils/Language";
import ContextSystem from "../../../utils/ContextSystem";
import { Button, ButtonFilled } from "../../FormComponents";
import styled from "styled-components";
import { Order, OrderPayment } from "../../../model/Order";
import { AllPaymentMethod, PaymentMethods, PaymentMethodTypes } from "../../../model/PaymentMethodSetting";
import { Shop } from "../../../model/Shop";
import { BiChevronLeft } from "react-icons/all";
import CartCalc from "../../../utils/CartCalc";
import { Product } from "../../../model/Product";
import { ShippingMethods } from "../../../model/ShippingPrice";
import { TabBar, TabItem } from "../../home/Orders";
import BillSplitByProductsComponent from "./BillSplitByProductsComponent";
import PayInOneComponent from "./PayInOneComponent";
import ConfirmationModal from "../ConfirmationModal";
import { debug } from "request";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Total = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 0 6px 0;
  margin: 6px 0 6px 0;

  border-bottom: 1px solid #e0e0e0;
  font-weight: bold;
  font-family: Arial, sans-serif;
  color: #666666;
`;

const TotalItem = styled.div`
  margin: 0 24px;
`;

const PaymentDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  margin-top: 12px;
`;

export default class OrderPaymentEditor extends Component {
  state: {
    language: number,
    selectedShop: Shop,
    showModal: boolean,
    selectedTab: number,
    newPayments: OrderPayment[],
    existingPayments: OrderPayment[],
    deletePayments: OrderPayment[],
    cart: Product[],
    cb: ()=>{},
    showBackButton: boolean,
  } = {
    language: ContextSystem.language,
    selectedShop: ContextSystem.selectedShop,
    showModal: false,
    selectedTab: 0,
    newPayments: [],
    existingPayments: [],
    deletePayments: [],
    cart: [],
    cb: undefined,
    showBackButton: false,
  };

  saveButtonClicked() {
    EventSystem.publish(EventSystem.events.order_payment_save_clicked);
  }

  returnAndClose() {
    this.state.cb && this.state.cb({ newPayments: this.state.newPayments, deletePayments: this.state.deletePayments });
    this.setState({
      showModal: false,
    });
  }

  saveFinished() {
    let totalPayments: number = OrderPayment.getPaidSum(this.state.newPayments);
    totalPayments -= OrderPayment.getPaidSum(this.state.deletePayments);
    totalPayments += OrderPayment.getPaidSum(this.state.existingPayments.filter(ep => this.state.deletePayments.find(dp => dp.id === ep.id) !== undefined));

    let orderTotalPrice: number = CartCalc.calcOrderProductsTotalPrice(this.state.cart);

    if (totalPayments > orderTotalPrice * 1.2) {
      ConfirmationModal.showModal(
        Language.getName(Names.Sure),
        Language.getName(Names.TooMuchPayment),
        Language.getName(Names.Yes),
        Language.getName(Names.CancelButtonText),
        () => {
        },
        () => this.returnAndClose(),
      );
      return;
    }

    this.returnAndClose();
  }

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.open_order_payment, data => {
      let { existingPayments, newPayments, cart, cb, force, showBackButton }: {
        existingPayments: OrderPayment[],
        newPayments: OrderPayment[],
        cart: Product[],
        showBackButton: boolean,
        cb: ({ newPayments: OrderPayment[], deletePayments: OrderPayment[], cancelled: boolean, back: boolean })=>{},
        force: boolean
      } = data;

      //no use for this popup window
      if (force !== true && (OrderPayment.getPaidSum(newPayments) > 0 || this.state.selectedShop.showCashflowReportAtOrder !== true)) {
        cb && cb({ newPayments });
        return;
      }

      this.setState({
        showModal: true,
        newPayments,
        showBackButton,
        existingPayments,
        deletePayments: [],
        cart,
        cb,
        selectedTab: 0,
      });
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language, selectedShop }) => {
      if (language !== undefined)
        this.setState({ language });
      if (selectedShop !== undefined)
        this.setState({ selectedShop: ContextSystem.selectedShop });
    });
  }

  changeTab(tabIndex: number) {
    this.setState({
      newPayments: [],
      deletePayments: [],
      selectedTab: tabIndex,
    });
  }

  static getAvailablePaymentMethods(mergeSzepCardsToCredit: boolean): { type: number, countries: number[], availableShippingMethods: number[] }[][] {
    let availablePaymentMethods: { type: number, countries: number[], availableShippingMethods: number[] }[][] = [];
    AllPaymentMethod.forEach(p => {
      if (PaymentMethods.isOnline(p.type))
        return;
      if (!p.availableShippingMethods.includes(ShippingMethods.PICKUP))
        return;
      let pms = ContextSystem.selectedShop?.paymentMethods?.find(pay => pay.paymentID === p.type && pay.active);
      if (!pms)
        return;
      if (!p.countries.includes(ContextSystem.selectedShop?.countryID))
        return;
      if (p.type === PaymentMethodTypes.CASH_BIG_BILLS)
        return;

      if (mergeSzepCardsToCredit && [PaymentMethodTypes.LOCAL_SZEP_CARD_KH, PaymentMethodTypes.LOCAL_SZEP_CARD_OTP, PaymentMethodTypes.LOCAL_SZEP_CARD_MKB, PaymentMethodTypes.ONLINE_SZEP_KH, PaymentMethodTypes.ONLINE_SZEP_MKB, PaymentMethodTypes.ONLINE_SZEP_MKB, PaymentMethodTypes.COD_SZEP_CARD_KH, PaymentMethodTypes.COD_SZEP_CARD_MKB, PaymentMethodTypes.COD_SZEP_CARD_OTP].includes(p.type))
        return;

      if (availablePaymentMethods.length <= 0 || availablePaymentMethods[availablePaymentMethods.length - 1].length >= 4)
        availablePaymentMethods.push([]);
      availablePaymentMethods[availablePaymentMethods.length - 1].push(p);
    });
    return availablePaymentMethods;
  }

  cancelModal(back: boolean = false) {
    //back is used when the panel is opened after TableSelector, and we need to open back again the table selector
    this.setState({ showModal: false });
    if (this.state.cb)
      this.state.cb({ cancelled: true, back });
  }

  static removeBtnText = <BiChevronLeft />;

  render() {
    let total: number = CartCalc.calcOrderProductsTotalPrice(this.state.cart);
    let paidSum: number = OrderPayment.getPaidSum(this.state.existingPayments);
    paidSum -= OrderPayment.getPaidSum(this.state.deletePayments);
    paidSum += OrderPayment.getPaidSum(this.state.newPayments);
    let order: Order = CartCalc.createOrderFromState(this.state.cart, this.state.existingPayments);

    return (
      <Modal size={"full_scroll"}
             show={this.state.showModal}
             onEscapeKeyDown={() => this.cancelModal()}
      >
        <Modal.Header>
          <Modal.Title>{Language.getName(Names.SetOrderPayments)}</Modal.Title>
        </Modal.Header>
        <Modal.Body padding={"0 6px"}>
          <Wrapper>
            <TabBar font_size={"9pt"}>
              <TabItem selected={this.state.selectedTab === 0} onClick={() => this.changeTab(0)}>
                {Language.getName(Names.PayTogether)}
              </TabItem>
              <TabItem selected={this.state.selectedTab === 1} onClick={() => this.changeTab(1)}>
                {Language.getName(Names.EasySplit)}
              </TabItem>
              <TabItem selected={this.state.selectedTab === 2} onClick={() => this.changeTab(2)}>
                {Language.getName(Names.ItemizedSplit)}
              </TabItem>
            </TabBar>
            {this.state.showModal &&
              <>
                <Total>
                  <TotalItem>{paidSum}Ft</TotalItem>
                  <TotalItem>/</TotalItem>
                  <TotalItem>{Math.round(total).toLocaleString()} Ft</TotalItem>
                </Total>
                <PaymentDetails>
                  {this.state.selectedTab === 0 &&
                    <PayInOneComponent
                      total={total}
                      newPayments={this.state.newPayments}
                      existingPayments={this.state.existingPayments}
                      deletePayments={this.state.deletePayments}
                      onNewPaymentsChanged={newPayments => this.setState({ newPayments })}
                      onDeletePaymentsChanged={deletePayments => this.setState({ deletePayments })}
                      onSaveProcessed={() => this.saveFinished()}
                      orders={[order]}
                    />
                  }
                  {this.state.selectedTab === 1 &&
                    <BillSplitByProductsComponent
                      cart={this.state.cart}
                      newPayments={this.state.newPayments}
                      deletePayments={this.state.deletePayments}
                      onNewPaymentsChanged={newPayments => this.setState({ newPayments })}
                      onDeletePaymentsChanged={deletedPayments => this.setState({ deletedPayments })}
                      existingPayments={this.state.existingPayments}
                      onSaveProcessed={() => this.saveFinished()}
                      splitEqually={true}
                    />
                  }
                  {this.state.selectedTab === 2 &&
                    <BillSplitByProductsComponent
                      cart={this.state.cart}
                      newPayments={this.state.newPayments}
                      deletePayments={this.state.deletePayments}
                      onNewPaymentsChanged={newPayments => this.setState({ newPayments })}
                      onDeletePaymentsChanged={deletedPayments => this.setState({ deletedPayments })}
                      existingPayments={this.state.existingPayments}
                      onSaveProcessed={() => this.saveFinished()}
                      splitEqually={false}
                    />
                  }
                </PaymentDetails>
              </>
            }
          </Wrapper>
        </Modal.Body>
        <Modal.Footer>
          {this.state.showBackButton &&
            <Button variant="secondary" onClick={() => this.cancelModal(true)}>
              {Language.getName(Names.Back)}
            </Button>
          }
          <Button variant="secondary" onClick={() => this.cancelModal()}>
            {Language.getName(Names.Close)}
          </Button>
          <ButtonFilled variant="primary" onClick={() => this.saveButtonClicked()}>
            {Language.getName(Names.Pay)}
          </ButtonFilled>
        </Modal.Footer>
      </Modal>
    );
  }
}
