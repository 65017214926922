// noinspection SpellCheckingInspection

import ContextSystem from "./ContextSystem";
import React from "react";
import styled from "styled-components";

const Button = styled.button`
  display: ${({ inline }) => inline ? "inline-block" : "block"};
  background-color: white;
  border: 1px solid #3b6499;
  border-radius: 5px;
  padding: 8px 20px;
  outline: none;
  margin: 0 auto;

  font-size: 12pt;
  font-family: "Raleway Black", sans-serif;
  text-align: center;
  transition: height 0.2s ease-in-out, background-color 200ms ease-in-out, color 200ms ease-in-out;
  color: #3b6499;

  &:hover, &:active {
    outline: none;
    background-color: #4a7dbf;
    cursor: pointer;
    color: white;
  }

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 600px) {
    margin: 0;
    padding-left: 2px;
    padding-right: 2px;
    font-size: 12pt;
  }
`;

export const Names = {
  MergeSzepCardsToCredit: ["SZÉP kártya összeg bankkártyához csatolása", "Merge all SZÉP card payments to credit card"],
  CannotChangeOrderWhileSearching: ["Keresés közben nem állítható a termékek sorrendje.", "You can't change the orders while searching."],
  DefaultCategorySaved: ["Alapértelmezett kategória mentve", "Saved as default category"],
  PleaseSelectASplit: ["Kérlek válaszd ki először, hogy melyik részhez / részekhez tartozik ez a tétel.", "Please select first the split(s) which should have this bill item."],
  ChangeBillLogo: ["Blokkon szereplő szürkeárnyalatos logó cseréje", "Modify black & White logo on printed receipt"],
  D_AJT: ["Adójegyes", "Tax stamped"],
  BillLogo: ["Blokkon szereplő szürkeárnyalatos logó", "Black & White logo on printed receipt"],
  View: ["Nézet", "View"],
  NewView: ["Új nézet", "New view"],
  ModifyView: ["Nézet módosítása", "Modify view"],
  ChooseWhichProductsShouldAppear: [
    "Válaszd ki, mely termékeket jelenítsük meg ajánlatként", "Choose which of your products should appear as an offer",
  ],
  ChooseWhichProductsShouldTrigger: [
    "Válaszd ki, milyen termékek szerepeljenek a kosárban, ahhoz, hogy az megjelenítsük az ajánlatot",
    "Choose which of your products needs to be in cart to trigger this offer",
  ],
  ChooseWhichProductsShouldTrigger2: [
    "Ha üres a lista, akkor minden esetben megjelenítjük az ajánlatot.",
    "If the list is empty, we will show the offer in all cases.",
  ],
  ModifyUpsellRule: ["Meglévő upsell szabály módosítása", "Modify existing upsell rule"],
  NewUpsellRule: ["Új upsell szabály", "New upsell rule"],
  DateCreated: ["Létrehozás időpontja", "Date of creation"],
  APP_AT_END_OF_ORDER: ["Vendégnél rendelés végén", "In app at the end of order"],
  APP_WHILE_ORDERING: ["Vendégnél rendelés közben", "In app while ordering"],
  APP_BEFORE_PAYMENT: ["Vendégnél fizetés előtt", "In app before payment"],
  APP_AFTER_PAYMENT: ["Vendégnél fizetés után", "In app after payment"],
  APP_AFTER_PRODUCT_VIEW: ["Vendégnél termék megtekintése után", "In app after product click"],
  POS_AT_END_OF_ORDER: ["Pultosnál rendelés végén", "POS at the end of order"],
  POS_WHILE_ORDERING: ["Pultosnál rendelés felütése közben", "POS while ordering"],
  UpSellCrossSellRules: ["Up Sell és Cross Sell tanítási szabályok", "Up Sell & Cross Sell learning rules"],
  UpSellCrossSell: ["Up Sell & Cross Sell", "Up Sell & Cross Sell"],
  NTAK: ["NTAK Integráció (API v4.04)", "NTAK Integration (Hungarian market, API v4.04)"],
  NoNTAKIntegrations: [
    "Jelenleg még nincs éles NTAK integraációd. Integráció elindításához regisztrálj az ntak.hu oldalon és válaszd ki, hogy a Blundee rendszert használod. Rendszer azonosító: BLUNDEE, verzió: 4.0.4. Ezek után küldünk egy .csr fájlt, amit az NTAK felületére fel kell töltened, majd onnan letöltheted a .cer tanúsítványfájlt. Integráció élesítéséhez a .cer fájlra van szükségünk.",
    "This restaurant doesn't have an NTAK integration at the moment. To start, please register at ntak.hu and upload the .csr file which we will provide for you. At the NTAK platform select that you're using Blundee, (RMS system: BLUNDEE, version 4.0.4), and download the .cer file. To finish the integration, we will need the .cer file.",
  ],
  NTAKDetails: [
    "NTAK (Nemzeti Turisztikai Adatszolgáltató Központ) integráció 2023 júliusától kötelező majdnem minden magyar vendéglátósnak. Lényege az, hogy minden rendelésről egy összesítőt a nap végén egy napzárási összesítőt kell szolgáltatni az MTÜ számára.",
    "This integration is mandatory and can be applicable only in the hungarian market.",
  ],
  NTAKDetails2: [
    "Integráláshoz először az NTAK (ntak.hu) weboldalán kell regisztrálni az Ügyfélportál azonosításával. A webes felületen egy tanúsítványt fájlt szükséges letölteni, amit később ide kell feltölteni az integrálás elindításához.",
    "",
  ],
  NTAKDetails3: [
    "A Blundee integrációja készen áll, viszont a további beállítások várhatóan csak júniustól lesznek elérhetők a rendszeren belül. További részletes tájékoztatásért hívj minket nyugodtan!",
    "",
  ],
  LocalVAT: ["Helyszíni rendelés ÁFA mértéke (%)", "VAT of local orders (%)"],
  DeliveryVAT: ["Szállítási rendelés ÁFA mértéke (%)", "VAT of delivery orders (%)"],
  TakeAwayVAT: ["Elviteles rendelés ÁFA mértéke (%)", "VAT of takeaway orders (%)"],
  VATSettings: ["Étterem ÁFA beállításai", "Restaurant VAT settings"],
  VATValues: ["ÁFA értékek", "VAT values"],
  PleaseFillOutTheCountedPart: ["Kérlek számold össze a befolyt összegeket", "Please count the amounts received"],
  COPHistory: ["Napi zárások", "COP History"],
  COPs: ["Napi zárások", "Close"],
  Never: ["Soha", "Never"],
  LastCOP: ["Legutóbbi napi zárás", "Last close-of-pay"],
  CountedSum: ["Számolt összeg", "Counted sum"], //Close of pay
  InventoryBased: ["Áruforgalmi elszámolás", "Inventory based cashflow"], //Close of pay
  Cashflow: ["Pénzforgalom", "Cashflow"],
  COP: ["Zárás", "COP"], //Close of pay
  Address: ["Cím", "Address"],
  AddressCommentPlaceholder: ["Megjegyzés, pl.: 2. emelet, 3. ajtó", "Comment, e.g.: 2nd floor, door 3"],
  LocationReadError: [
    "Nem sikerült beolvasni az aktuális helyzeted. Kérlek próbáld meg beírni.",
    "We couldn't get your locaiton. Please try to enter it manually.",
  ],
  Next: ["Tovább", "Next"],
  HouseNumberMissing: ["Add meg a házszámod is!", "Please enter the house number as well."],
  NoResults: ["Nincs találat", "No results"],
  PleaseWait: ["Kis türelmet", "Loading"],
  SomethingWentWrong: [
    "Valami hiba került a rendszerbe :( Kérlek próbáld újra.", "Something went wrong :( Please try again later.",
  ],
  MaxCharactersComment: [
    "Maximum 200 karakter adható a megjegyzésnek!", "You should enter only 200 characters for a comment.",
  ],
  NoMoreDetailsNeeded: ["Elviteles rendelés, a vendég értejön", "Takeaway order, the guest will pick it up"],
  AddView: ["Új nézet", "Add view"],
  Unselect: ["Kiválasztás törlése", "Unselect"],
  PleaseSelectASavedMovement: [
    "Kérlek válassz ki egy mentett raktármozgatást.", "Please select a saved storage movement.",
  ],
  PleaseEnterASavingName: [
    "Kérlek add meg a raktármozgatás mentési nevét.", "Please enter a saving name for this storage movement.",
  ],
  Assigned: ["Illetékes", "Assigned"],
  AvailablePOS: ["Eladható bent", "Available for employees"],
  AvailableOnline: ["Online elérhető", "Available online"],
  SavedName: ["Mentett név", "Saved name"],
  RequestName: ["Kérelem neve", "Request name"],
  LoadSaved: ["Mentett betöltése", "Load saved"],
  PayLaterAvailableText: ["Később fizetek funkció elérhető a vendégek számára (éttermen belüli rendeléseknél)", "Pay later available online for in-house orders"],
  posStandShowLocalOrders: ["Helyi rendelések megjelenítése a pultos nézetben", "Show local orders in Counter layout"],
  PosWaiterShowNonLocalOrdersText: [
    "Elviteles és futáros rendelések megjelenítése pincérnézetben",
    "Show non-local orders (takeaway and delivery) in the Waiter layout",
  ],
  autoPrintOrders: ["Rendelések automatikus nyomtatása", "Auto print orders after confirmation"],
  kitchenReportAvailable: [
    "Konyhaállapotok nyilvántartása (Kikapcsolás esetén a nem jelenik meg konyha statisztika és a rendelések is elkerülik a \"Készül\" és \"Elkészült\" állapotokat)",
    "Kitchen report available (If disabled, the statistics on the kitchen will be hidden, and orders' state won't go through the kitchen, meaning the system will skip \"Preparing\" and \"Prepared\" orderstates)",
  ],
  serverReportAvailable: [
    "Felszolgálás nyilvántartása (Kikapcsolás esetén a rendeléseknél nem fog megjelenni a \"Felszolgálom\" opció, ezzel átugorva a \"Felszolgálva\" állapotot, és egyből a \"Kész\" állapotba kerül. Továbbá statisztikában nem lesz elérhető felszolgálási idővel kapcsolatos kimutatás.",
    "Service record (If disabled, the \"Serve\" option will not appear on the orders, thus skipping the \"Served\" state and going straight to the \"Ready\" state. Furthermore, no statement related to the service time will be available in the statistics.",
  ],
  cashflowReportAvailable: [
    "Pontos bevétel nyilvántartása (Kikapcsolás esetén a rendszer nem fog fizetési adatokat bekérni, így minden ezzel kapcsolat statisztikai adat nem lesz elérhető)",
    "Accurate income registration (If disabled, the system will not request payment data, so all related statistical data will not be available)",
  ],
  showCashflowReportAtOrder: [
    "Fizetési felület mutatása új rendelés felvételekor", "Show payment interface when placing a new order",
  ],
  hideNotAvailableProducts: [
    "Nem elérhető termékek teljes elrejtése vendégeg elől", "Hide inactive products from guests",
  ],
  showOrderTimerForGuests: [
    "Rendelés elkészítési idő visszaszámláló megjelenítése a vendégnek", "Show order timer for guests",
  ],
  automaticallyAcceptOnlineOrders: ["Online rendelések automatikus visszaigazolása", "Accept online orders automatically"],
  TotalMoveQty: ["Összes mozgatott mennyiség:", "Total moving quantity:"],
  Newest: ["Legújabb", "Newest"],
  Oldest: ["Legrégebbi", "Oldest"],
  Remaining: ["Hátralévő", "Remaining"],
  Add: ["Hozzáad", "Add"],
  Courier: ["Futár", "Courier"],
  Amount: ["Fizetendő", "Amount"],
  MoveStorage: ["Raktár mozgatás", "Move storage"],
  Move: ["Mozgatás", "Move"],
  ManualSelection: ["Manuális kiválasztás", "Manual selection"],
  Automatic: ["Automata", "Automatic"],
  SELL: ["Értékesítés", "Purchase"],
  LOSS: ["Veszteség", "Loss"],
  INVENTORY_DIFFERENCE: ["Leltározás", "Stocktaking, inventory difference"],
  MOVE_BETWEEN_STORAGE: ["Mozgatás raktárak között", "Move between storages"],
  REVERSE_TRANSACTION: ["Sztornó", "Storno"],
  MANUFACTURE: ["Készítés / Gyártás", "Pre-processing, manufacturing"],
  WASTE: ["Selejtezés", "Disposal"],
  NewArrival: ["Bevételezett áru", "New arrival"],
  Events: ["Események", "Events"],
  History: ["Előzmények", "History"],
  NotDefined: ["Nem meghatározott", "Not defined"],
  Items: ["Raktárelemek", "Items"],
  ShowOnlyShortages: ["Csak hiánycikkek mutatása", "Show only shortages"],
  servings: ["adag", "serving(s)"],
  Choose: ["Válassz", "Choose"],
  Select: ["Kijelölés", "Select"],
  ProcessedProducts: ["Félkész termékek", "Processed products"],
  ProcessedProduct: ["Félkész termék", "Processed product"],
  Counter: ["Pultos készlet", "Counter stock"],
  Warehouse: ["Háttérraktár", "Background warehouse"],
  PrintDocumentErrorText: ["Hiba történt a rendelés nyomtatásakor.", "Error occured while trying to print the order."],
  Default: ["Alapértelmezett", "Default"],
  DefaultStorage: ["Alap. tároló", "Default storage"],
  UsedStorage: ["Használt raktár", "Used storage"],
  AllowAnotherStorage: ["Más raktár használata", "Allow use of another storage"],
  AnotherStorageUsable: ["Más raktár használható", "Other storages are usable"],
  OnlyThisStorage: ["Kizárólagos tároló", "Only this storage is usable"],
  Printing: ["Nyomtatás", "Printing"],
  PrintingDetails: [
    "Itt tudod beállítani, hogy egyes termékek rendelés vagy rendelés visszaigazolás után melyik nyomtatón érkezzen meg. A nyomtatás alapbeállítása a következő: Ha nincs kijelölve egyes feltétre, termékváltozatra nyomató, akkor az a termék beállítását követi. Hiányos nyomtatásbeállítás a terméknél viszont az alapnyomtatóra fog érkezni (a nyomtatólista közül az első)., ",
    "At this window you can set the products on which printer should come out after ordering or confirming an order. The default printing settings are as follows: If no printer is selected for a particular topping or product version, then the settings are inherited from the product. The default printing settings for the product will be the default printer (the first in the printer list).",
  ],
  ShippingFees: ["Szállítási díjak", "Shipping fees"],
  GivenDiscounts: ["Kedvezmények", "Given discounts"],
  Unlimited: ["Korlátlan mennyiség", "Unlimited quantity"],
  AvailableQty: ["Felhasználható mennyiség", "Available quantity"],
  Validity: ["Érvényesség ideje", "Validity"],
  Fix_Amount: ["Fix összeg", "Fix amount"],
  Percentage: ["Százalékos", "Percantage"],
  ServiceCharge: ["Szervizdíj", "Service charge"],
  TimeOfOfferAppearance: ["Ajánlat mutatásának időpontja", "Time of offer appearance"],
  Type: ["Típus", "Type"],
  Code: ["Kód", "Code"],
  ID: ["Azonosító", "ID"],
  AllRevenue: ["Összes forgalom", "All revenue"],
  RevenueWithoutDiscounts: ["Kedvezmény nélküli forgalom", "Revenue without discounts"],
  AllIncome: ["Összes bevétel", "All income"],
  Consumption: ["Fogyás", "Consumption"],
  Unassigned: ["Nincs hozzárendelve", "Unassigned"],
  Tip: ["Borravaló", "Tip"],
  Tips: ["Jatt", "Tips"],
  AggregatedTips: ["Aggregált jatt", "Aggregated tips"],
  Update: ["Frissítés", "Update"],
  DaysUpToToday: [" nap máig", " days up to today"],
  DaysUpToYesterday: [" nap tegnapig", " days up to yesterday"],
  LastWeek: ["Előző hét", "Last week"],
  ThisWeek: ["Tárgyhét", "This week"],
  LastMonth: ["Előző hónap", "Last month"],
  ThisMonth: ["Tárgyhónap", "This month"],
  HourlyIncome: ["Bevétel óránként", "Hourly income"],
  MonthlyIncome: ["Havi bevétel", "Monthly income"],
  YearlyIncome: ["Éves bevétel", "Yearly income"],
  DailyIncome: ["Napi bevétel", "Daily income"],
  Income: ["Bevétel", "Revenue"],
  Prepared: ["Előállított", "Prepared"],
  ProductPrepared: [
    "Kész termék (nem szükséges konyhában előállítani)",
    "Product is prepared (preparation in the kitchen is not needed)",
  ],
  ChooseZone: ["Válassz területet", "Choose zone"],
  AllZones: ["Összes terület", "All zones"],
  WithoutZone: ["Terület nélküli", "Without zone"],
  NewZone: ["Új terület", "New zone"],
  NewCoupon: ["Új kedvezmény", "New coupon"],
  CustomOpenHours: ["Egyedi nyitvatartás", "Custom open hours"],
  ModifyZone: ["Terület módosítása", "Modify zone"],
  ModifyCoupon: ["Kedvezmény módosítása", "Modify coupon"],
  Zones: ["Területek", "Zones"],
  Copied: ["Másolva", "Copied to clipboard"],
  CopyProduct: ["Termék másolása", "Copy product"],
  EditProduct: ["Termék módosítása", "Modify product"],
  NewProduct: ["Új termék", "New product"],
  OrderPrintFooter1: ["Köszönjük a vásárlást! Jó étvágyat!", "Thank you for your purchase!"],
  OrderPrintFooter2: ["Nem adóügyi bizonylat", "Not a tax receipt"],
  OrderPrintFooter3: ["Készpénz átvételére nem jogosít!", "Not valid for cash payment!"],
  SelectedExtras: ["Kiválasztott feltétek:", "Selected extras:"],
  SelectCustomer: ["Kiválasztás", "Select"],
  NotPaidYet: ["Még nincs kifizetve!", "Not paid yet!"],
  Delay: ["Késés", "Delay"],
  RemainingTime: ["Hátralévő idő", "Remaining time"],
  AssignTable: ["Asztalhoz rendelés", "Assign table"],
  TableChange: ["Átültetés", "Reseat"],
  NoSOS: ["Minden rendben üzemel 👌", "Everything is running smoothly 👌"],
  EmptyKitchenTickets: ["Minden rendeléssel megvagy 👌", "No more orders left 👌"],
  TableClosed: ["Asztal lezárva", "Table closed"],
  _30min: ["30 perc", "30 minutes"],
  _1h: ["1 óra", "1 hour"],
  _1_5h: ["1,5 óra", "1.5 hour"],
  _2h: ["2 óra", "2 hours"],
  _3h: ["3 óra", "3 hours"],
  Period: ["Időtartam", "Period"],
  Arrival: ["Érkezés", "Arrival"],
  Guest: ["Vendég", "Guest"],
  Guests: ["Vendégek", "Guests"],
  OtherGuest: ["Másik vendég", "Choose another guest"],
  Online: ["Online", "Online"],
  AcceptOnlineBookings: ["Online foglalások", "Accept online bookings"],
  AddNewCustomer: ["Vendég hozzáadása", "Add customer"],
  NewReservation: ["Új asztalfoglalás", "New reservation"],
  NotImplementedYet: ["Fejlesztés alatt...", "Not implemented yet... under development"],
  Split: ["Osztás", "Split"],
  NoReservations: ["Nincs foglalás", "No bookings"],
  WalkIn: ["Belépett", "Walk-in"],
  DeselectTable: ["Asztal nélkül", "Without table"],
  LaterItemizedSplit: [
    "Fejlesztés alatt... Később itt tudod szétszedni a rendelést 'Termékenként', aszerint, hogy ki melyik terméket szeretné fizetni.",
    "Under development ... Later you will be able to split the order here the 'Itemized' way: splitting the bill based on products.",
  ],
  LaterEasySplit: [
    "Fejlesztés alatt... Később itt tudod szétszedni a rendelést 'egyszerű szétszedés' módon, aszerint, hogy ki mennyit szeretne fizetni.",
    "Under development ... Later, here you will be able to split the order in an 'easy-split' way: splitting the bill based on currency.",
  ],
  OrderPaymentErrorAfterOrder: [
    "Nem sikerült elmenteni a rendelés fizetési adatait. Kérlek a rendeléseknél ezt ismételd meg.",
    "Couldn't save payment information about this order. Please try again from the 'Orders' panel.",
  ],
  OrderPaymentError: [
    "Nem sikerült elmenteni a rendelés fizetési adatait. Kérlek próbáld újra később.",
    "Couldn't save payment information about this order. Please try again later.",
  ],
  CANCELLATION_ORDER_MIXED: ["Hiba. Kevert típusú rendelés.", "Error. Mixed types in one order."],
  NO_TABLE_AVAILABLE: ["A kiválasztott asztal nem elérhető.", "The selected table is not available."],
  COUPON_CODE_ALREADY_IN_USE: ["A választott kuponkódot már használják.", "The given coupon code is taken."],
  PAYMENT_METHOD_NOT_VALID: ["Fizetési módot nem fogadja el a rendszer jelenleg", "Payment method is not valid"],
  NOT_ENOUGH_QTY_TO_MOVE: ["Nincs elegendő mennyiség a raktárban!", "Not enough quantity in the storage to move."],
  RESERVATION_ALREADY_ACCEPTED: ["A foglalást már visszaigazolták.", "The reservation has been confirmed already."],
  RESERVATION_NOT_FOUND: ["Foglalás nem található.", "Reservation not found."],
  UnknownError: ["Ismeretlen hiba. Kód: ", "Unknown error. Code: "],
  PleaseLogIn: ["Kérlek jelentkezz be", "Please log in"],
  DatabaseError: ["Adatbázis hiba.", "Database error."],
  PermissionDenied: ["Hozzáférés megtagadva.", "Permission denied."],
  DataNotFound: ["Rekord nem található. Kérlek próbáld újra később.", "Data not found. Please try again later."],
  MenuNotRemovable: ["Termék nem törölhető, mivel még egy menü része!", "Product is contained in a menu."],
  OrderNotFound: ["Rendelés nem található. Kérlek próbáld újra később.", "Order not found. Please try again later."],
  DeleteOrderInsteadOfModify: [
    "Ha törölni szeretnéd a rendelést, ne rendelést ürítsd, hanem kattints a rendelés törlésére.",
    "For removing an order, please click on 'Remove' instead of clearing the order.",
  ],
  Reset: ["Visszaállítás", "Reset"],
  BalanceDue: ["Fizetendő", "Balance Due"],
  EasySplit: ["Egyszerű szétosztás", "Easy split"],
  PayTogether: ["Egybe fizetés", "Pay Together"],
  ItemizedSplit: ["Termékenkénti szétosztás", "Itemized split"],
  Coupons: ["Kedvezmények", "Discounts"],
  PriceAdjustments: ["Ármódosítók", "Price Modifiers"],
  CouponsAndCharges: ["Kedvezmények és díjak", "Discounts and charges"],
  AdditionalCharges: ["További díjak", "Additional charges"],
  UsedCoupons: ["Használt kedvezmények (db)", "Used discounts (pcs)"],
  CouponsAmount: ["Kedvezmények összege", "Amount of discounts"],
  Customer: ["Vendég", "Customer"],
  NoCustomersToShow: ["Nem találtam vendéget", "No customers to show"],
  CustomerName: ["Vendég neve", "Customer's name"],
  SearchCustomer: ["Vendég keresése...", "Search for customer..."],
  SetOrderPayments: ["Rendelés fizetése", "Checkout order"],
  Payment: ["Fizetés", "Add payment"],
  Pay: ["Fizetés", "Pay"],
  AddReservation: ["Új foglalás", "New reservation"],
  Upcoming: ["Közelgő", "Upcoming"],
  Seat: ["Ültetés", "Seat"],
  Seated: ["Ültetve", "Seated"],
  person: ["fő", "person"],
  Cover: ["Személyek száma", "Cover"],
  CoverRange: ["Ültethető személyek", "Cover range"],
  MinCover: ["Min ültethető személy", "Minimum cover"],
  MaxCover: ["Max ültethető személy", "Maximum cover"],
  Back: ["Vissza", "Back"],
  EditProductRecipes: ["Felhasznált receptúrák szerkesztése", "Edit used recipes"],
  ChooseRecipesForProduct: [
    "Válaszd ki, hogy a termék milyen receptúrákból áll.", "Choose which recipes are used for this product.",
  ],
  RecipeFor10Servings: ["Receptúra 10 adagra", "Recipe for 10 servings"],
  Dashboard: ["Áttekintés", "Dashboard"],
  Recipes: ["Receptúrák", "Recipes"],
  PleaseEnterYourInwardHandling: [
    "Kérlek itt töltsd fel a bevételezési bizonylat részleteit a rendszerben lévő nyersanyagokra.",
    "Please enter details of your receipt note.",
  ],
  PleaseEnterYourProduction: [
    "Kérlek itt töltsd fel az előállított termékeket és félkész termékeket. Mentés után a beállított receptek alapján kerül levonásra a nyersanyag.",
    "Please upload your finished and processed products here. After saving, the ingredients will be deducted according to the recipes.",
  ],
  InwardHandling: ["Bevételezés", "Inward handling"],
  Production: ["Gyártás", "Production"],
  NoConnection: ["Nincs kapcsolat!", "No connection!"],
  _12hours: ["12 óra", "12 hours"],
  _1day: ["1 nap", "1 day"],
  _3days: ["3 nap", "3 days"],
  _5days: ["5 nap", "5 days"],
  _1week: ["1 hét", "1 week"],
  _2weeks: ["2 hét", "2 weeks"],
  _3weeks: ["3 hét", "3 weeks"],
  _1month: ["1 hónap", "1 month"],
  _2months: ["2 hónap", "2 months"],
  _3months: ["3 hónap", "3 months"],
  ShelfLifeDesc: [
    "Szavatossági idő percben (max felhasználási időtartam)", "Shelf life in minutes (max period of use after supply)",
  ],
  ShelfLifeInMinutes: ["Szavatossági idő percben", "Shelf life in minutes"],
  ShelfLife: ["Szavatossági idő", "Shelf life"],
  MinAndMaxQty: ["Minimum és maximum mennyiség", "Minimum and max. quantity"],
  MinQty: ["Minimum mennyiség", "Minimum quantity"],
  MaxQty: ["Maximum mennyiség", "Maximum quantity"],
  NewRaw: ["Nyersanyag felvétele", "Add new ingredient"],
  AddNewRaw: ["Új nyersanyag létrehozása", "Create new ingredient"],
  AddNewCategory: ["Új kategória létrehozása", "Create new category"],
  NewRecipe: ["Receptúra felvétele", "Add new recipe"],
  NewStorage: ["Tároló felvétele", "Add new storage"],
  AddNewRecipe: ["Új receptúra létrehozás", "Create new recipe"],
  AddNewStorage: ["Új tároló létrehozás", "Create new storage"],
  CRM: ["CRM", "CRM"],
  CRM_and_Coupons: ["CRM & Kedvezmények", "CRM & Coupons"],
  Raws: ["Nyersanyagok", "Ingredients"],
  Ingredient: ["Nyersanyag", "Ingredient"],
  Storages: ["Raktárak", "Storages"],
  SelectTable: ["Válassz egy asztalt", "Select a table"],
  Summary: ["Összesítő", "Summary"],
  Sum: ["Összeg", "Sum"],
  CollectedSum: ["Befolyt összeg", "Collected sum"],
  NoSummaryText: ["Nincs foglalás", "No bookings"],
  TableDetails: ["Asztal részletei", "Table details"],
  FilterPaidTable: ["Fizetett / Nem fizetett", "Paid / Unpaid"],
  FilterBookedTable: ["Üres / Foglalt", "Empty / Booked"],
  Booked: ["Foglalt", "Booked"],
  Book: ["Foglalás", "Book"],
  SendToKitchen: ["Konyhára", "Send"],
  Or: ["vagy", "or"],
  PinNumber: ["Belépés utáni PIN kód (4-8 szám)", "PIN code after login (4-8 numbers)"],
  PasswordTooShort: ["A jelszavad legyen legalább 5 karakteres!", "Password is too short (min. 5)"],
  ModifyProfile: ["Felhasználó módosítása", "Modify user's permission"],
  AddProfile: ["Felhasználó meghívása", "Invite a new user"],
  CantDeleteLastManagerUser: [
    "Az utolsó admin jogosultságú felhasználó nem törölhető.", "You can't remove the last user with admin rights.",
  ],
  CantModifyPrimaryUser: [
    "Az kapcsolattartó felhasználó engedélyei nem módosíthatók..", "You can't modify the primary contact.",
  ],
  CANT_DELETE_EXTRA_PRODUCT_USE_IT: ["A feltét még néhány termék része, így még nem törölhető", "You can't delete this extra, since it's used by products."],
  CantDeletePrimaryUser: ["Az kapcsolattartó felhasználó nem törölhető.", "You can't remove the primary contact."],
  CantDeleteLastUser: ["Az utolsó felhasználó nem törölhető.", "You can't remove the last user."],
  Permissions: ["Engedélyek", "Permissions"],
  Users: ["Felhasználók", "Users"],
  InviteUser: ["Meghívó küldése emailre", "Invite by email"],
  PrimaryContact: ["Kapcsolattartó", "Primary contact"],
  InvitationPending: ["Függőben lévő meghívó", "Invitation pending"],
  ProfileNotFound: ["Felhasználó nem található", "Profile not found"],

  TooMuchPayment: [
    "Az összes fizetés min. 20%-kal meghaladja a rendelés végösszegét. Biztosan lezárja a rendelést?",
    "All payments are at least 20% higher than the total amount of the order. Are you sure you want to close your order?",
  ],
  NoPaymentOnStandingError: ["A rendelés még nincs kifizetve!", "The order is still not paid."],
  Sure: ["Biztos?", "Sure?"],
  DoYouReallyWantToRemoveItem: ["Biztos, hogy törlöd a tételt?", "Do you really want to remove this item?"],
  ClearCartConfirmationText: ["Biztosan üríteni szeretnéd a meglévő kosarat?", "Do you really want to clear the cart?"],
  ResetOriginalOrderText: [
    "Biztosan visszaállítod az eredeti rendelést?", "Do you really want to reset the cart to the original order?",
  ],
  ChangeAllPaymentToOne: [
    "Az eddigi fizetési adatok törölve lesznek és a számla végösszege a kijelölt fizetési móddal lesz. Biztos, hogy így szeretnéd?",
    "Payments made so far will be canceled and your account balance will remain in the form of payment you have chosen. Are you sure you want to?",
  ],
  ToppingMinNotReachedText: ["Nincs elég feltét kiválasztva!", "You need to select more toppings."],
  ToppingMaxReachedTextPart1: ["Ebből a csoportból legfeljebb ", "You can only select "],
  ToppingMaxReachedTextPart2: ["db feltétet választhatsz!", "pcs from this topping."],
  AddToCart: ["Kosárba", "Add to cart"],
  ChooseToppings: ["Módosítási lehetőségek ", "Options available"],

  ToppingFreeQtyTextPart1: ["Ingyenes ", "Choose "],
  ToppingFreeQtyTextPart2: [" db!", " for free!"],
  ToppingMinMaxQtyTextPart1: ["akármennyi", "freely from these"],
  ToppingMinMaxQtyTextPart2: ["akár ", "at most"],
  ToppingMinMaxQtyTextPart3: ["db", "pcs from these"],
  ToppingMinMaxQtyTextPart4: ["min.", "min."],
  ToppingMinMaxQtyTextPart5: ["db", "pcs from these"],
  ToppingQtyTextPart1: ["Válassz ", "You can choose "],
  ToppingQtyTextPart2: [" db-ot!", "!"],
  ToppingQtyTextPart3: [" db-ot ingyen!", " for free."],
  KindTopping: ["féle feltét", "topping kind"],

  WhichVersionText: ["Melyik változatot szeretnéd?", "Which one would you like to choose?"],
  ErrorInShopMenuText: [
    "Sajnos hibát találtunk az étlapban! Kérlek válassz a többi feltét közül.",
    "We have detected an error in this product. Please try choosing another topping.",
  ],
  ProductNotAvailableNow: ["A termék most nem elérhető.", "Product is not available now."],
  WoltIntegrationInfo: ["Wolt integráció", "Wolt integration"],
  WoltIntegrationDetails: [
    "Ha a Wolttal szerződve vagy és fogadsz online rendelést, akkor integrálással tudod őket kezelni és szinkronizálni az Blundee platformmal. Így egy helyen kezelheted az összes rendelésed. Szinkronizálja az integráció továbbá az étlapot, ahol százalékos árkülönbség-jutalékot tudsz beállítani. Az integrációhoz beállítása kb. 2 hét, ehhez keresd fel az ügyfélszolgálatunkat.",
    "If you have a contract with Wolt and accept online orders, you can integrate them and manage them with the Blundee platform. This way you can manage all your orders in one place. The integration also synchronizes the menu, where you can set a percentage price difference commission. Setting up for integration takes approx. 2 weeks, contact our customer service for this.",
  ],
  DeliveryHeroIntegrationInfo: ["Delivery Hero integráció", "Delivery Hero integration"],
  DeliveryHeroIntegrationDetails: [
    "Delivery Hero integrációhoz tartozik a Foodpanda, Hungerstation, Otlob, Talabat, Damejidlo, eFood, Foodora, Hungry, Mjam, Pauza. Ha ezekről a felületekről fogadsz online rendelést, akkor integrálással tudod őket kezelni és szinkronizálni az Blundee platformmal. Így egy helyen kezelheted az összes rendelésed. Szinkronizálja az integráció továbbá az étlapot, ahol százalékos árkülönbség-jutalékot tudsz beállítani. Az integrációhoz beállítása kb. 2 hét, ehhez keresd fel az ügyfélszolgálatunkat.",
    "Delivery Hero integration includes Foodpanda, Hungerstation, Otlob, Talabat, Damejidlo, eFood, Foodora, Hungry, Mjam, Pauza. If you order online from these interfaces, you can integrate them and manage them with the Blundee platform. This way you can manage all your orders in one place. The integration also synchronizes the menu, where you can set a percentage price difference commission. Setting up for integration takes approx. 2 weeks, contact our customer service for this.",
  ],
  MenuSubProductsMin1: [
    "A kapcsolt termékeknél 1db a minimum mennyiség (", "The minimum qty of the linked products is 1pcs (",
  ],
  Description: ["Leírás", "Description"],
  EditExtrasButtonTextPostfix: ["db csoport", "groups"],
  EditExtrasButtonText: ["Feltétek szerkesztése", "Edit toppings"],
  LinkedSubProducts: ["Kapcsolt altermékek", "Linked sub-products"],
  ExtraName: ["Feltét neve", "Extra / Topping name"],
  ProductName: ["Terméknév", "Product name"],
  CopyMenuTitle: ["Ajánlat másolása", "Copy Special offer"],
  EditMenuTitle: ["Ajánlat módosítása", "Modify Special offer"],
  AddNewMenuTitle: ["Ajánlat készítése", "Add new Special offer"],
  YouNeedAtLeastOneImage: ["Kérlek tölts fel legalább egy képet.", "You need to upload at least one image."],
  DescriptionTooShort: ["A megadott leírás túl rövid.", "Please enter a longer description."],
  NameTooLong50: [
    "A megadott név túl hosszú (max. 50 karakter lehet)", "Please enter a shorter name (max. 50 characters).",
  ],
  NameTooShort: ["Kérlek adj meg egy hosszabb nevet.", "Please enter a longer name."],
  CouponCodeTooShort: ["Kód túl rövid", "Code is too short"],
  SpecialOffer: ["Különleges ajánlat", "Special offer"],
  ShortName: ["Rövid név", "Short name"],
  NoCategoriesAvailable: ["Nincs kiválasztható kategória", "No categories available"],
  NoItemsAvailable: ["Nincs kiválasztható elem", "No items available"],
  ChooseRestaurantCategory: ["Válassz éttermi kategóriát!", "Select a restaurant category"],
  ChooseNTAKMainCategory: ["Válassz NTAK főkategóriát!", "Select an NTAK main category"],
  ChooseNTAKSubCategory: ["Válassz NTAK alkategóriát!", "Select an NTAK subcategory"],
  ChooseNTAKMennyisegEgyseg: ["Válassz NTAK mennyiség egységet!", "Select an NTAK unit type"],
  NTAKMennyiseg: ["NTAK mennyiség eladott tételenként", "NTAK quantity per sold product"],
  ChooseCategory: ["Válassz kategóriát!", "Select a category"],
  AllDay: ["0 - 24", "0 - 24"],
  WeekScheduleText: [
    "Termékelérhetőség (Mikor rendelhető a termék?)", "Product availability schedule (When can it be ordered?)",
  ],
  OrderDetailsNotFound: ["Rendelés részleteit nem sikerült betölteni.", "Order details failed to load."],
  ShowNotConfirmedOrders: ["Visszaigazolatlan rendelések mutatása", "Show not confirmed orders"],
  ShowHiddenOrders: ["Rejtett rendelések", "Show hidden"],
  FilterShippingModes: ["Szűrj rendelés típusára", "Filter based on shipping mode"],
  Clear: ["Ürítés", "Clear"],
  All: ["Összes kiválasztása", "Select all"],
  AllSelected: ["Összes kiválasztva", "All selected"],
  ChooseStorages: ["Raktárak kiválasztása", "Choose storages"],
  ChooseStorage: ["Raktár kiválasztása", "Choose storage"],
  AllStorages: ["Összes raktár", "All storages"],
  AllTypes: ["Összes típus", "All types"],
  TableNotFound: ["Nem található az asztal.", "Table not found."],
  NoTablesToSelectFrom: ["Nincs kiválasztható asztal", "There is no table which could be selected"],
  NoTablesToShow: ["Még nincs asztal a rendszerben.", "No tables to show."],
  NoZonesToShowAddRightTop: [
    "Még nincs zóna a rendszerben. Hozzáadáshoz kattints a jobb felső sarokban lévő plusz gombra!",
    "No zones to show yet. You can add a table to the system with the button on the top-right corner.",
  ],
  NoTablesToShowAddRightTop: [
    "Még nincs asztal a rendszerben. Hozzáadáshoz kattints a jobb felső sarokban lévő plusz gombra!",
    "No tables to show yet. You can add a table to the system with the button on the top-right corner.",
  ],
  NoRawMaterialsToShow: ["Még nincsenek nyersanyagok / összetevők a rendszerben", "No ingredient to show yet"],
  ChooseRaw: ["Válassz nyersanyagot", "Add an ingredient to the list"],
  NoRecipesToShow: ["Még nincsenek receptúrák a rendszerben", "No recipes to show yet"],
  NoRawMaterialsToShowAddRightTop: [
    "Még nincsenek nyersanyagok / összetevők a rendszerben. Hozzáadáshoz kattints a jobb felső sarokban lévő plusz gombra! Az itteni nyersanyagok segítségével receptúrákat állíthatsz be egyes termékekhez.",
    "No ingredient to show yet. You can add an ingredient to the system with the button on the top-right corner. You can set up recipes later in at the product settings using these ingredients.",
  ],
  NoRecipesToShowAddRightTop: [
    "Még nincsenek receptúrák a rendszerben. Hozzáadáshoz kattints a jobb felső sarokban lévő plusz gombra! A receptúrákat tudod később felhasználni a termékeknél és feltéteknél a készletkalkuláció pontosítása érdekében.",
    "No recipes to show yet. You can add a new recipe to the system with the button on the top-right corner. You can use recipes later in at the product settings to handle stock calculations correctly.",
  ],
  NoCouponsToShowAddRightTop: [
    "Még nincsenek a rendszerben kedvezmények. Hozzáadáshoz kattints a jobb felső sarokban lévő plusz gombra!",
    "Your system doesn't contain any coupons yet. You can add a new customer to the system with the button on the top-right corner.",
  ],
  NoProfilesToShowAddRightTop: [
    "Még nincsenek a rendszerben vendégkör. Hozzáadáshoz kattints a jobb felső sarokban lévő plusz gombra!",
    "Your CRM is empty. You can add a new customer to the system with the button on the top-right corner.",
  ],
  LoggedInAsAdminNoPassChange: [
    "Adminként vagy bejelentkezve, így nem tudsz jelszót módosítani. Saját admin jelszavadat az admin oldalon módosítsd.",
    "You can't change password since you are logged in as an admin. If you wish to change your admin profile's password, please log in to the admin panel.",
  ],
  ContentOfMenu: ["Menü tartalma összesen", "Content of menu in total"],
  pcs: ["db", "pcs"],
  QR_IDShouldBePositive: [
    "A QR azonosító egy 10 számjegyű számsor. Kérem adja meg, hogy milyen azonosító szerepel a beolvasott QR kódban.",
    "The given QR identification should be a positive number. Please check the ID number in your QR code. It should contain a 10 digit number",
  ],
  QR_IDAlreadyInUse: [
    "A QR azonosítót már használják. Kérem adja meg, hogy milyen azonosító szerepel a beolvasott QR kódban.",
    "The given QR identification is already in use. Please check the ID number in your QR code.",
  ],
  ProductImage: ["Termékkép", "Product image"],
  ShortDailyNumber: ["Napi azonosító", "Daily number"],
  CoverImagePC: ["Számítógépes nézet", "PC mode"],
  CoverImageMobile: ["Mobilos nézet", "Mobile mode"],
  ShowPreparedOrdersBeforeMinutesLabel: [
    "Időzített rendelések visszaigazolás után elrejtődnek. Időzített időpont előtt mennyivel (perc) jelenítsük meg őket újra? (min. 5, max 120)",
    "Scheduled orders will be hidden after confirmation. How many minutes before the scheduled time should we show them again? (min. 5, max. 120",
  ],
  ShowPreparedOrdersBeforeMinutesLabel2: [
    "Rejtett rendeléseket ettől függetlenül külön meg lehet tekinteni a Rendelések fülön.",
    "Hidden orders can still be viewed separately on the Orders tab.",
  ],
  PreparedOrderMaxDaysAtMost7Error: [
    "Időzített rendelést legfeljebb 7 nappal a rendelés előtt lehet leadni.",
    "You can only accept orders within 7 days at most.",
  ],
  PreparedOrderShowMinAtMost2HoursError: [
    "Időzített rendelést legfeljebb 2 órával a rendelés előtt jelenítjük meg újra automatikusan (120perc).",
    "A scheduled order can be re-displayed after confirmation automatically up to 2 hours before the order is due.",
  ],
  PreparedOrderMaxDaysLabel: [
    "Maximum hány nappal előtte rendelhetnek? (min. 1, max. 7)",
    "How many days in advance can a scheduled order take place? (min. 1, max. 7)",
  ],
  PreparedOrderOnlyOnlinePaymentLabel: [
    "Időzített rendelésnél csak online fizetés elfogadása.", "Accept only online payments while pre-ordering.",
  ],
  PreparedOrderMaxDaysExample: ["pl. 7", "e.g. 7"],
  ShowPreparedOrdersBeforeMinutesExample: ["pl. 30", "e.g. 30"],
  Email: ["Email cím", "Email address"],
  PhoneNumber: ["Telefonszám", "Phone number"],
  PublicEmail: ["Publikus email cím", "Public email address"],
  PublicPhone: ["Publikus telefonszám", "Public phone number"],
  PublicContactInfo: ["Publikus kapcsolati információk", "Public contact information"],
  PrepareOrderSettings: ["Időzített rendelések beállítása", "Settings of scheduled orders"],
  OrderProcessingSettings: ["Étterem működési beállítások", "Restaurant operation settings"],
  PublicContactInfoDetails: [
    "Ezeket az információkat jelenítjük meg a vendégek felé.", "These information are present to the consumers.",
  ],
  ChangeCoverImages: ["Üzlet borítóképének módosítása", "Change cover image of this shop"],
  OtherCommentForGuest: ["Egyéb megjegyzés a vendég felé...", "Other comment for the guest..."],
  eg: ["pl.", "eg."],
  QR_ID: ["QR azonosító", "QR identification"],
  DownloadQR: ["QR kód", "QR code"],
  DownloadAllQR: ["QR kódok", "QR codes"],
  QR_WillBeGenerated: [
    "Az asztal egyedi QR kódja generálva lesz.", "The unique QR code of this table will be generated.",
  ],
  minutes: ["perc", "minute(s)"],
  PleaseChooseAnOptionFirst: ["Kérlek válassz egyet előbb.", "Please choose an option first."],
  ChooseDeliveryTime: ["Mikor szállítjuk ki a vendéghez?", "When is the delivery expected?"],
  PleaseSetPayments: ["Kérlek vezesd fel a vendég fizetését.", "Please record the payments of the guest."],
  PaidUntilNow: ["Eddigi fizetett összeg:", "Already paid:"],
  LeftToPay: ["Hátralévő:", "Left:"],
  ChoosePreparationTime: ["Mikor lesz elkészítve?", "When is the preparation is expected?"],
  AddComment: ["Megjegyzés hozzáfűzése", "Add comment"],
  Other: ["Egyéb", "Other"],
  Approx: ["Kb", "Approx"],
  DefaultVersion: ["Alap verzió", "Default version"],
  NoShopsConnectedToYourProfile: [
    "Nincs egy üzlet sem ezen a profilon.", "No shop or restaurant is available for this profile.",
  ],
  NoImageUploaded: ["Kérlek tölts fel egy képet.", "Upload an image."],
  NoImageUploadedErrorText: ["Kérlek tölts fel egy képet.", "Upload an image first"],
  AddImage: ["Kép hozzáadása", "Add image"],
  UploadImage: ["Kép feltöltése", "Upload image"],
  SelectFile: ["Fájl kiválasztása", "Select a file"],
  CropImageText: ["Kép kivágása (? arány)", "Crop image (? ratio)"],
  ExtraSelectorTitle: ["Rakd össze a feltéteket", "Select toppings"],
  ExtraIsAlreadyInList: ["Ez a feltét már szerepel a listában.", "This topping is already listed."],
  MinEGQtyBelowZero: ["Min. feltétek száma nem lehet 0 alatt.", "Min. toppings' number shouldn't be below 0."],
  MaxEGQtyBelowZero: ["Max. feltétek száma nem lehet 0 alatt.", "Max. toppings' number shouldn't be below 0."],
  FreeEGQtyBelowZero: ["Ingyenes feltétek száma nem lehet 0 alatt.", "Free toppings' number shouldn't be below 0."],
  Max5ProductVersion: ["Maximum 5 termékváltozat lehet!", "You can have at most 5 product versions."],
  YouNeedAtLeastOneProductVersion: [
    "Legalább egy termékváltozatnak lennie kell!", "You need at least one product version.",
  ],
  ProductVersionPriceNonNegative: ["Termékváltozat ára legalább 0!", "Product version's price shouldn't be below 0."],
  CopyFromOtherProduct: ["Másolás más termékről", "Copy from another product"],
  SearchForProduct: ["Termék keresése...", "Search for product..."],
  NoProductToShow: ["Nincs kiválasztható termék", "No product to show"],
  VersionName: ["Verzió neve", "Version name"],
  ToppingGroupName: ["Feltétcsoport neve", "Topping group name"],
  EnterAGroupName: ["Írj be egy csoportnevet...", "Enter a group name..."],
  min: ["min", "min"],
  Min: ["Min", "Min"],
  Max: ["Max", "Max"],
  BasePriceContains: ["Alapár tartalmazza", "Base price contains"],
  ToppingName: ["Feltét neve", "Topping name"],
  MaxQuantity: ["Max. mennyiség", "Max. quantity"],
  MinQuantity: ["Termék mennyisége legalább 1 lehet", "Quantity of the product is min. 1"],
  Quantity: ["Mennyiség", "Quantity"],
  QuantityFor10: ["Mennyiség (10 adagra)", "Quantity (for 10 servings)"],
  IngredientsFor10: ["Hozzávalók 10 adagra", "Ingredients for 10 servings"],
  ShowingName: ["Megjelenített név", "Showing name"],
  AddTopping: ["Feltét hozzáadása", "Add topping"],
  SearchToppings: ["Feltét keresése...", "Search for topping..."],
  NoToppingsToShow: ["Nincs kiválasztható feltét", "No toppings to show"],
  AddNewTopping3Dots: ["Új feltét létrehozása...", "Add new topping..."],
  CopyConfirmTextPart1: ["A(z) ", "The product "],
  CopyConfirmTextPart2: [" termékről összesen ", " contains "],
  CopyConfirmTextPart3: [" termékváltozat ", " product version(s) "],
  CopyConfirmTextPart4: [" feltétcsoport ", " topping group(s) with "],
  CopyConfirmTextPart5: [" feltéttel ", " topping(s) "],
  CopyConfirmTextPart6: [" kerül átmásolásra.", " which will be copied."],
  CopyConfirmTextPart7: ["Fontos", "Note"],
  CopyConfirmTextPart8: [
    (<>
      az eredeti termékváltozatok, feltétcsoportok és feltétek törlése kerülnek a feltétszerkesztőből,
      így a <Button inline>Mentés</Button> gombra kattintás után azok már nem állíthatók vissza!<br />
    </>)
    ,
    (<>
      The product version, topping groups and topping options with all settings will be removed from this product,
      thus after clicking on <Button inline>Save</Button>, you won't be able to restore the original settings.<br />
    </>),
  ],
  And: ["és", "and"],
  NoExtrasOnSelectedProduct: [
    "A kiválasztott terméken nincsenek feltétek vagy termékverziók, így nem lehet róla másolni.",
    "There is nothing to copy from the selected product.",
  ],
  ShallWeContinue: ["Folytatjuk?", "Shall we continue?"],
  ExtraNameTooShort: ["A feltét neve túl rövid", "The name of the topping is too short."],
  GivenNameTooShort: ["A megadott név túl rövid", "The name is too short."],
  GivenDetailsTooShort: ["A megadott leírás túl rövid", "The detials is too short."],
  GivenUnitTooShort: ["A megadott mértékegység túl rövid", "The unit is too short."],
  PriceShouldBeNonNegative: ["Az ár nem lehet 0-nál kisebb.", "The price shouldn't be below 0"],
  NewTable: ["Új asztal", "New table"],
  ModifyTable: ["Asztal módosítása", "Modify table"],
  NewExtra: ["Új feltét", "New topping"],
  ModifyExtra: ["Feltét módosítása", "Modify topping"],
  ModifyRaw: ["Nyersanyag módosítása", "Modify ingredient"],
  ModifyRecipe: ["Receptúra módosítása", "Modify recipe"],
  ModifyStorage: ["Tároló módosítása", "Modify storage"],
  ContractUnderProcessText: [
    "Már van egy folyamatban lévő vagy hatályos szerződés!",
    "You already have a contract in progress or a valid contract!",
  ],
  CheckContract: ["Szerződés megtekintése", "Check contract"],
  Date: ["Dátum", "Date"],
  SignedBy: ["Aláírta", "Signed by"],
  CombinedMenu: ["Kombinált Menü", "Combined menu"],
  BankAccountNumber: ["Bankszámlaszám", "Bank account number"],
  BankName: ["Bank neve", "Name of bank"],
  CompanyHQ: ["Cég székhelye", "Company address"],
  CompanyName: ["Cégnév", "Company name"],
  CompanyVAT: ["Cég adószáma", "Company VAT number"],
  ContractStateError: ["Hibás", "Failed"],
  ContractStateStopped: ["Bontva", "Terminated"],
  ContractStateDeclined: ["Szerződés elutasítva", "Declined"],
  ContractStateValid: ["Hatályos", "Valid"],
  ContractStateNew: ["Új szerződés", "New contract"],
  NoContractsYetText: [
    "Még nincs szerződés a rendszerben. Létrehozunk egyet?",
    "I couldn't find any contracts in the system. Shall we start one?",
  ],
  OnlyEmptyCategoryCanBeRemoved: ["Csak üres kategóriát lehet törölni!", "You can only remove empty categories."],
  YouNeedMinOneCategory: ["Legalább 1 kategóriának kell lennie!", "You need at least one category."],
  Max2LevelCategories: [
    "Legfeljebb 2 szintű kategóriarendszer lehet a vendégek kényelmes böngészése miatt.",
    "You can only define 2 levels of subcategories, so consumers find it easy to use.",
  ],
  MaxSubCategories: [
    "Főkategórián belül legfeljebb 10 gyermekkategória lehet!",
    "Max numbers of second-level categories in a main category is 10",
  ],
  MaxMainCategories: ["Legfeljebb 10 főkategória lehet!", "Max numbers of top level categories is 10."],
  ProductCategories: ["Termékkategóriák", "Product categories"],
  ConclusionOfContract: ["Szerződéskötés", "Conclusion Of Contract"],
  OrderReceivedTextPart1: ["", "You got "],
  OrderReceivedTextPart2: [
    " darab rendelésed érkezett! Igazold vissza az új rendelést!", " order(s)! Confirm them right away! ;)",
  ],
  OrderReceived: ["Rendelés érkezett! - Blundee", "Orders received! - Blundee"],
  BrowserSettings: ["Böngésző beállítás", "Browser settings"],
  IncomingOrders: ["Bejövő rendelések", "Incoming orders"],
  AskPermissionForPushNotification: [
    (
      <>
        Szeretnénk rendszerértesítőt küldeni, amikor új rendelés érkezik.<br />
        Kérlek engedélyezd az értesítés küldését, hogy minél gyorsabban tudjuk kiszolgálni a vendégeket!<br />
        <br />
        Köszönöm!
      </>
    ),
    (
      <>
        We would like to send you a system notification when a new order arrives. <br />
        Please allow the notification to be sent so we can serve your costumers as quickly as possible! <br />
        <br />
        Thanks!
      </>
    ),
  ], NoPermissionForPushNotification: [
    (
      <>
        Nem adtad meg az engedélyt, hogy értesítést tudjunk küldeni számodra.<br />
        Ha szeretnél rendszerszintű értesítést kapni a bejövő rendelésekről, kérlek engedélyezd a böngésző
        beállításai között.
      </>
    ),
    (
      <>
        You have not given permission to send a browser notification. <br />
        If you would like to receive system-wide notifications of incoming orders, please enable your browser.
        settings.
      </>
    ),
  ],
  RestaurantPageText: ["Étteremoldal", "Restaurant page"],
  ActivateBtnText: ["Étterem aktiválása", "Activate restaurant"],
  ActivateTitle: ["Üdvözöllek az Blundee.com partneroldalán!", "Welcome to the partner site of Blundee!"],
  ActivateDesc: [
    "Kérlek adj meg egy új jelszót, hogy később be tudj lépni az adminisztrációs oldaladra!",
    "Please add a new password, which you will use to log in to this page.",
  ],
  EnniAkarokActivatePageTitle: ["Blundee Partner Aktiválás", "Blundee Partner Activate"],
  EnniAkarokActivatePageDesc: ["Partneroldal aktiválás", "Partner page activation"],
  ErrorPage404Text: [
    (<>Ooupsz! :( Az oldal nem található!<br />404-es hiba</>), (<>Ooo... :( Can't find this page<br />Error
      404.</>),
  ],
  ChangePassword: ["Jelszó megváltoztatása", "Change password"],
  ChangeProfileDetails: ["Profiladatok módosítása", "Modify profile details"],
  CurrentPassword: ["Jelenlegi jelszó", "Current password"],
  PayingCycleValuePart1: ["", "Every "],
  PayingCycleValuePart2: ["napos", "day"],
  NotGiven: ["Nincs megadva", "Not given"],
  PaymentMethodIsNotSet: ["Nincs fizetési mód beállítva", "Payment method is not set up"],
  GivenPasswordsTooShort: ["A megadott jelszavak túl rövidek", "The given passwords are too short."],
  Categories: ["Kategóriák", "Categories"],
  NTAKCategories: ["NTAK Fő- és alkategória", "NTAK Main and subcategories"],
  Save: ["Mentés", "Save"],
  Saved: ["Mentve", "Saved"],
  Category: ["Kategória", "Category"],
  Details: ["Részletek", "Details"],
  Ok: ["Ok", "Ok"],
  ProductInsideMenuTextPart1: ["Ez a termék a", "This product is part of menu: "],
  ProductInsideMenuTextPart2: [
    "menü része, így nem törölheted! Előbb töröld a menüt.", "Please remove the menu first.",
  ],
  DeletedProductErrorText: ["Hiba, törölt termék", "Error, removed product"],
  ProductIsNotRemovable: ["Termék nem törölhető", "Product is not removable"],
  PCS: ["db", "pcs"],
  Print: ["Nyomtatás", "Print"],
  Accepted: ["Átvette", "Accepted"],
  Accept: ["Elfogad", "Accept"],
  Accept2: ["Fogadás", "Accept"],
  Send: ["Küldés", "Send"],
  Delivered: ["Kiszállítva", "Delivered"],
  Served: ["Felszolgálva", "Served"],
  Paid: ["Fizetve", "Paid"],
  Unpaid: ["Nem fizetett", "Unpaid"],
  UnpaidTable: ["Nem fizetett asztal", "Unpaid table"],
  OpenTable: ["Nyitva hagyott asztal", "Still open table"],
  NotConfirmedOrder: ["Visszaigazolatlan rendelés", "Not confirmed order"],
  StuckOrder: ["Beragadt rendelés", "Stuck order"],
  DelayedOrder: ["Késett rendelés", "Delayed order"],
  DelayedTablesOrder: ["Késett asztali rendelés", "Delayed table-order"],
  EmptyIngredient: ["Kevés nyersanyag", "Empty ingredient"],
  EmptyList: ["Üres lista", "Empty list"],
  NotAccepted: ["Nem vette át", "Not accepted"],
  AtShipping: ["Szállítás alatt", "At shipping"],
  Shipping: ["Szállítás", "Shipping"],
  Done: ["Kész", "Done"],
  Preparing: ["Készül", "Preparing"],
  Confirm: ["Visszaigazolás", "Confirm"],
  Decline: ["Elutasítás", "Decline"],
  Storno: ["Sztorno", "Storno"],
  Restore: ["Visszaállítás", "Restore"],
  NoActionsAvailable: ["Nincs elérhető művelet", "No actions available"],
  Actions: ["Műveletek", "Actions"],
  Comment: ["Megjegyzés", "Comment"],
  Note: ["Megjegyzés", "Note"],
  AddressCantBeLoaded: ["Cím nem betölthető", "Address can't be loaded"],
  Export: ["Export", "Export"],
  Search: ["Keresés", "Search"],
  StartDate: ["Kezdő dátum", "Date from"],
  EndDate: ["Végső dátum", "Date to"],
  Filters: ["Szűrők", "Filters"],
  Filter: ["Szűrő", "Filter"],
  Order: ["Rendelés", "Order"],
  Arrived: ["Beérkezett", "Arrived at"],
  order: ["rendelés", "order"],
  NewOrders: ["Új rendelések", "New orders"],
  DoneOrders: ["Kész rendelések", "Done orders"],
  ConfirmedOrders: ["Folyamatban", "In progress"],
  Total: ["Végösszeg", "Total"],
  Subtotal: ["Részösszeg", "Sub Total"],
  State: ["Állapot", "State"],
  OrderIDText: ["Rendelés azonosító", "Order number"],
  ProfileIDText: ["Felhasználói azonosító", "Profile ID"],
  EmptyTable: ["Üres asztal", "Empty table"],
  Dash: ["-", "-"],
  Free: ["Szabad", "Free"],
  NoOrders: ["Nincs rendelés", "There is no order"],
  NoOrders2: ["Nincs rendelés", "No orders"],
  PaymentMethods: ["Fizetési módok", "Payment methods"],
  PaymentMethod: ["Fizetési mód", "Payment method"],
  PaymentMethod3Dot: ["Fizetési mód...", "Payment method..."],
  SZEP_ONLINE_MKB: ["Online Szép kártya (MKB)", "Szép card online (MKB)"],
  SZEP_ONLINE_OTP: ["Online Szép kártya (OTP)", "Szép card online (OTP)"],
  SZEP_ONLINE_KH: ["Online Szép kártya (K&H)", "Szép card online (K&H)"],
  TAKEAWAY_CREDIT_CARD: ["Helyszíni bankkártya", "Credit card on premise"],
  ONLINE_MOBILEPAY_DK: ["Mobilepay", "Mobilepay"],
  LOCAL_SZEP_CARD_MKB: ["Helyszíni Szép kártya (MKB)", "Szép card on premise (MKB)"],
  LOCAL_SZEP_CARD_OTP: ["Helyszíni Szép kártya (OTP)", "Szép card on premise (OTP)"],
  LOCAL_SZEP_CARD_KH: ["Helyszíni Szép kártya (K&H)", "Szép card on premise (K&H)"],
  FOODORA: ["Foodora", "Foodora"],
  WOLT: ["Wolt", "Wolt"],
  CREDITCARD_ONLINE: ["Online bankkártya", "Credit card"],
  COD_SZEP_KH: ["Futárnál Szép kártya (K&H)", "Szép card on delivery (K&H)"],
  COD_SZEP_OTP: ["Futárnál Szép kártya (OTP)", "Szép card on delivery (OTP)"],
  COD_SZEP_MKB: ["Futárnál Szép kártya (MKB)", "Szép card on delivery (MKB)"],
  CODCredit: ["Futárnál bankkártya", "Credit card on delivery"],
  Cash: ["Készpénz", "Cash"],
  CashBigBill: ["Készpénz (nagy címlet)", "Cash (big bill)"],
  Delivery: ["Házhoz szállítás", "Delivery"],
  Delivery2: ["Átvétel", "Delivery"],
  VendorDelivery: ["Kiszervezett szállítás", "Vendor delivery"],
  AtPlace: ["Helyi fogyasztás", "Eat in"],
  ShippingMode: ["Szállítási mód", "Shipping mode"],
  Colleague: ["Munkatárs", "Colleague"],
  ShippingMode3Dot: ["Szállítási mód...", "Shipping mode..."],
  OrderStateNew: ["Új", "New"],
  OrderStateConfirmed: ["Visszaigazolva", "Confirmed"],
  OrderStateDeclined: ["Elutasítva", "Declined"],
  OrderStateDeleted: ["Törölve", "Deleted"],
  OrderStateDone: ["Kész", "Done"],
  OrderStateReady: ["Elkészítve", "Ready"],
  OrderState3Dot: ["Állapot...", "State..."],
  OrderStateFailedTrans: ["Meghiúsult", "Failed"],
  WaitingForOnlinePayment: ["Online fizetésre vár", "Waiting for online payment"],
  Copy: ["Másolás", "Copy"],
  Unhide: ["Megjelenítés", "Unhide"],
  Hide: ["Elrejtés", "Hide"],
  NewMenuBtnText: ["Új menü", "New menu"],
  RemovedFromVisibleMenus: ["Levéve az elérhető menük közül.", "Removed from visible menus."],
  SelectedVisibleMenus: ["Kiválasztott ajánlatok", "Selected offers"],
  ProductHiddenText: ["A termék már nem látható a vásárlók számára.", "Product is hidden."],
  ProductUnhiddenText: ["A termék újra látható a vásárlók számára.", "Product is visible."],
  OrderingSaved: ["Sorrend mentve", "Order saved"],
  ProductAddedToTheMenu: ["Felvéve az elérhető menük közé", "Product is added to the menu."],
  ProductIsAlreadyInMenu: ["A menü már szerepel a listában.", "This product is already in the menu."],
  Name: ["Név", "Name"],
  Name_WillBeDisplayed: ["Név (Ez fog szerepelni a vendég blokkján)", "Name (This will appear on the printed receipt)"],
  Price: ["Ár", "Price"],
  OriginalPrice: ["Eredeti ár", "Original price"],
  ShowPrice: ["Megjelenített ár", "Price shown"],
  UsedIngredients: ["Felhasznált nyersanyag", "Used ingredients"],
  AllUsedIngredients: ["Összes felhasznált nyersanyag", "Used ingredients total"],
  OpenBeforeWork: ["Étterem kezeléséhez előbb kapcsold át a nyitvatartást!", "Open shop before work."],
  Today: ["Ma", "Today"],
  Yesterday: ["Tegnap", "Yesterday"],
  CloseForToday: ["Mára bezárok", "Close for today"],
  OpenShop: ["Bolt nyitása", "Open shop"],
  Orders: ["Rendelések", "Orders"],
  pcsOfOrders: ["db rendelés", "orders"],
  FloorMap: ["Asztaltérkép", "FloorMap"],
  OpeningsAndShipping: ["Szállítás és nyitvatartás", "Opening and Shipping"],
  Settings: ["Beállítások", "Settings"],
  Tables: ["Asztalok", "Tables"],
  Table: ["Asztal", "Table"],
  Integrations: ["Integrációk", "Integrations"],
  Product: ["Termék", "Product"],
  Products: ["Termékek", "Products"],
  Menus: ["Különleges Ajánlatok", "Special deals"],
  Toppings: ["Feltétek", "Toppings"],
  Hidden: ["rejtett", "hidden"],
  Manager: ["Menedzser", "Manager"],
  Standing: ["Pult", "Counter"],
  Waiter: ["Pincér", "Waiter"],
  Dispatcher: ["Diszpécser", "Dispatcher"],
  Kitchen: ["Konyha KDS", "Kitchen KDS"],
  AMOrPM: ["De/Du", "AM / PM"],
  NoDaysToShow: ["Nincs megjeleníthető nap", "No days to show"],
  SelectADay: ["Kérem válasszon egy napot", "Please select a date"],
  StartingTimeIsAfterEndingPoint: [
    "Kezdeti idő nem lehet a zárási idő előtt", "The starting time should be before the ending time.",
  ],
  InvalidDataEntered: ["Érvénytelen adat", "The given data is not valid"],
  OpenHourOrCloseHourIsEmpty: [
    "Kérem adjon meg kezdeti és zárási időt!", "Please enter a starting and an ending time.",
  ],
  NoDiscountShippingPriceGiven: [
    "Adj meg egy kedvezményes szállítási díjat. Ingyenes esetén adja meg, hogy 0Ft a díj értéke. Ha nem szeretne kedvezményes szállítási díjat megadni, törölje a kedvezmény határ összegét.",
    "You forgot to put the discount shipping price. If it's free, please put 0. If you would like to disable discount price on this shipping area, please remove the discount min. cart value.",
  ],
  NoShippingPriceIsGiven: [
    "Adj meg egy szállítási díjat. Ingyenes esetén adjon meg 0 értéket.",
    "You forgot the put the price. If it's free, please put a 0.",
  ],
  MinCartValueIsNegative: [
    "Min. rendelési összeg nem lehet 0 alatti.", "Min. cart value should be 0 or a positive number.",
  ],
  SelectACity: ["Válassz ki egy várost!", "Select a city!"],
  DeleteShippingPriceConfirmDetails: [
    "Biztosan törlöd a szállítási területet?", "Are you sure you want to remove the shipping price?",
  ],
  DeleteShippingPriceConfirmTitle: ["Szállítási terület törlése", "Remove shipping price"],
  DeliveryOpenHoursText: ["Kiszállítás ideje", "Delivery time"],
  AcceptOrderTakeAwayOpenHoursText: ["Rendelés felvétel (személyes átvételre)", "Accept orders (for takeaway)"],
  AcceptOrderDeliveryOpenHoursText: ["Rendelés felvétel (kiszállításra)", "Accept orders (for delivery)"],
  ShopOpenHours: ["Üzlet nyitvatartása", "Open hours for the shop"],
  TakeAway: ["Elviteles", "Takeaway"],
  AcceptOrders: ["Rendelés átvételi beállítások", "Settings for order acceptance"],
  TakeAwayOption: ["Elviteles elérhető", "Enable takeaway"],
  DeliveryOption: ["Házhozszállítás elérhető", "Enable delivery"],
  AtPlaceOption: ["Helyi rendelés (asztalnál) elérhető", "Enable table orders"],
  ShippingPrice: ["Szállítási díj (Ft)", "Shipping price (Ft)"],
  MinShippingCartValueText: ["Min. rendelési összeg a fenti területre (Ft)", "Min. cart value for the above area (Ft)"],
  MinShippingCartValueText2: ["Minimum rendelési összeg (Ft)", "Minimum cart value (Ft)"],
  MinShippingCartValueText3: [
    "Kedvezményes rendelésösszeg határa (Ft)", "Discount shipping price min. cart value (Ft)",
  ],
  MinShippingCartValueText4Part1: ["Szállíási díj", "Shipping price over "],
  MinShippingCartValueText4Part2: ["Ft rendelés felett", "Ft cart value"],
  MinShippingCartValueText5: ["Kedvezményes szállítási díj (Ft)", "Discount shipping price (Ft)"],
  NoCitiesToShow: ["Nincs megjeleníthető város", "No cities to show"],
  NameOfTheCity: ["Város neve", "City name"],
  CustomShippingPriceText: [
    "Egyedi szállítási terület beállításához vedd fel a kapcsolatot az Ügyfélszolgálattal.",
    "For defining custom shipping areas, please call our customer service for partners.",
  ],
  MinCartValue: ["Min. rendelés összeg", "Min. cart value"],
  DiscountValue: ["Kedvezmény értéke", "Discount value"],
  ServiceChargeValue: ["Szervizdíj mértéke", "Service charge percentage"],
  ShippingPriceOverText: ["felett", "and above"],
  ShippingPlaces: ["Szállítási területek", "Shipping Places"],
  StockTaking: ["Leltározás", "Stock-taking"],
  Stock: ["Készlet", "Stock"],
  CountedStock: ["Leltárazott", "Counted"],
  AddButtonText: ["Hozzáadás", "Add"],
  Import: ["Import", "Import"],
  MessengerNotificationNeedSetupDetails: [
    "A messenger kapcsolat élesítéséhez a további lépések hozzáadás után jelennek meg.",
    "To activate this Messenger notification channel, you need to take the below steps.",
  ],
  TheNotificationChannelIsActive: ["Az értesítési kapcsolat éles.", "The notification channel is active"],
  MessengerNotificationSetUpText1: [
    "Az értesítési csatorna beállításához kérlek küldd el a", "To set up this channel, please send",
  ],
  MessengerNotificationSetUpText2: ["szöveget Botinak", "text to Boti,"],
  MessengerNotificationSetUpText3Link: ["Blundee ChatBotnak itt", "the Blundee ChatBot"],
  MessengerNotificationSetUpText4: [
    "Ha a fenti linken keresztül nem működik az élesítés, keress rá Messengerben a \"",
    "If the above link is not working, please search on Messenger for the account \"",
  ],
  MessengerNotificationSetUpText5: ["\" fiókra, és küldd el neki az alábbi üzenetet:", "\" and send this text:"],
  FirstName: ["Keresztnév", "First name"],
  LastName: ["Vezetéknév", "Last name"],
  SelectType: ["Válassz típust", "Select type"],
  SetUpNew: ["Új beállítása", "Set up a new one"],
  NotificationSettingAdded: ["Értesítés hozzáadva!", "Notification setting added successfully"],
  EmailAddressNotValid: ["Email cím helytelen!", "The given email address is not valid."],
  PhoneNumberNotValid: ["Telefonszám helytelen!", "The given phone number is not valid."],
  PinOnlyNumbers: ["PIN kód csak számjegyelből állhat.", "PIN code contains other characters."],
  PinTooShort: ["PIN kód túl rövid (4-8 számjegy)", "PIN code is too short (4-8 numbers)"],
  PinTooLong: ["PIN kód túl rövid (4-8 számjegy)", "PIN code is too long (4-8 numbers)"],
  ErrorTitle: ["Hiba", "Error"],
  ErrorMessageText: ["Oldal nem található", "Page not found"],
  LoginTitle: ["Bejelentkezés", "Login"],
  LoginPasswordPlaceholderText: ["Jelszó", "Password"],
  LoginButtonText: ["Bejelentkezés", "Log in"],
  HistoryTitle: ["Történet", "History"],
  HistoryFromText: ["Forrás", "From"],
  HistoryToText: ["Cél", "To"],
  HistorySenderPlaceholderText: ["Feladó", "Sender"],
  HistoryRecipientPlaceholderText: ["Címzett", "Recipient"],
  HistoryCancelledText: ["Visszamondva", "Cancelled"],
  HistoryAddressPlaceholderText: ["Cím", "Address"],
  HistoryPacketStatus: [
    [
      "Új",
      "Folyamatban",
      "Futár kijelölve",
      "Futár a feladónál",
      "Felvéve",
      "Futár a címzettnél",
      "Csomag leadva",
      "Kész",
      "Késik a futár (felvétel)",
      "Leadási hiba",
      "Késik a futár (leadás)",
      "Meghiúsult",
    ],
    [
      "New",
      "Pending",
      "Courier assigned",
      "Courier arrived origin",
      "Picked up",
      "Courier arrived destination",
      "Dropped off",
      "Done",
      "Courier too late for pickup",
      "Drop off not possible",
      "Courier too late for drop off",
      "Cancelled",
    ],
  ],
  MapTitle: ["Térkép", "Map"],
  MapNotificationBarJob: ["Aktív munka", "Active job"],
  MapNotificationBarJobOffer: ["Aktív ajánlat", "Active job offer"],
  MapOnButtonText: ["ON", "ON"],
  MapOffButtonText: ["OFF", "OFF"],
  MapLastOfferText: ["Utolsó ajánlat", "Last offer"],
  MapCurrency: ["Ft", "$"],
  MonthNames: [
    [
      "Január", "Február", "Márcus", "Április", "Május", "Június", "Július", "Augusztus", "Szeptember", "Oktober",
      "November", "December",
    ],
    [
      "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November",
      "December",
    ],
  ],
  DayNamesShort: [
    ["H", "K", "Sze", "Cs", "P", "Szo", "V"],
    ["M", "T", "W", "T", "F", "S", "S"],
  ],
  DayNames: [
    ["Hétfő", "Kedd", "Szerda", "Csütörtök", "Péntek", "Szombat", "Vasárnap"],
    ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
  ],
  MapHourText: ["óra", "hour(s)"],
  MapActiveDayText: ["aktív nap", "active day(s)"],
  MapNavigateButtonText: ["Indulás", "Navigate"],
  MapNavigateToNextButtonText: ["A következőhöz", "Navigate to next"],
  MapScanQrCodeText: ["QR kód beolvasása", "Scan QR Code"],
  MapOpenInMapButtonText: ["Térkép megnyitása", "Open in map"],
  MapCommentPlaceholderText: ["Megjegyzés", "Comment"],
  MapErrorButtonText: ["Hiba", "Error"],
  MapErrorTexts: [
    ["Késői felvéte", "Címzett nem található", "Cím nem található", "Megtagadta", "Egyéb"],
    ["Pickup too late", "Customer not found", "Address not found", "Declined", "Other"],
  ],
  MapErrorDescriptionPlaceholderText: ["Hiba leírás", "Error description"],
  Upload: ["Feltöltés", "Upload"],
  CancelButtonText: ["Mégsem", "Cancel"],
  SendButtonText: ["Küldés", "Send"],
  MapClearButtonText: ["Ürítés", "Clear"],
  MapJobOfferReceivedText: ["Munkaajánlat érkezett", "Job offer received"],
  Total2: ["Összesen", "Total"],
  MapCircaText: ["kb.", "ca."],
  MapCommissionText: ["Jutalék", "Commission"],
  MapFirstStopText: ["Első megálló", "First stop"],
  MapPackagesCountText: ["Csomagok száma", "Packages count"],
  ProfileTitle: ["Profil", "Profile"],
  ProfilePasswordChangeText: ["Jelszó módosítása", "Password change"],
  ProfileCurrentPasswordPlaceholderText: ["Jelenlegi jelszó", "Current password"],
  ProfileNewPasswordPlaceholderText: ["Új jelszó", "New password"],
  ProfileDataChangeText: ["Adatok módosítása", "Data change"],
  Added: ["Hozzáadva", "Added"],
  Modify: ["Módosítás", "Modify"],
  Modified: ["Módosítva", "Modified"],
  Remove: ["Törlés", "Remove"],
  Request: ["Kérés", "Request"],
  Removed: ["Törölve", "Removed"],
  ProfileFirstNamePlaceholderText: ["Keresztnév", "First name"],
  ProfileLastNamePlaceholderText: ["Vezetéknév", "Last name"],
  ProfileSignOutButtonText: ["Kijelentkezés", "Sign out"],
  MoreTitle: ["Egyéb", "More"],
  LoadMore: ["Továbbiak betöltése", "Load more"],
  MoreLanguageText: ["Nyelv", "Language"],
  SelectShopText: ["Üzlet", "Shop"],
  NoShopsToShow: ["Nincs üzlet", "No shops"],
  LayoutSelectText: ["Nézet", "Layout"],
  NoLanguagesToShow: ["Nincs megjeleníthető nyelv", "No lanaguage to show"],
  NoLayoutToShow: ["Nincs megjeleníthető nézet", "No layout to show"],
  MoreUsedMapText: ["Használt térkép", "Used map"],
  CompanyDetailsText: ["Cég részletek", "Company details"],
  MoreCompanyFormText: ["Kft.", "Ltd."],
  MoreVatNumberText: ["Adószám", "VAT Number"],
  MoreSupportText: ["Support", "Support"],
  MorePhoneNumberText: ["Telefonszám", "Phone number"],
  MoreAppVersionText: ["App verzió", "App version"],
  MiscEmailText: ["E-mail cím", "E-mail address"],
  MiscSaveButtonText: ["Mentés", "Save"],
  MinuteUnit: ["p", "m"],
  HourUnit: ["h", "h"],
  DayUnit: ["nap", "day"],
  DaysUnit: ["nap", "days"],
  WeekUnit: ["hét", "week"],
  WeeksUnit: ["hét", "weeks"],
  MonthUnit: ["hónap", "month"],
  MonthsUnit: ["hónap", "months"],
  YearUnit: ["év", "year"],
  YearsUnit: ["év", "years"],
  Unit: ["Mértékegység", "Unit"],
  MiscPieces: ["db", "pc"],
  CookieText: [
    "Weboldalunk használatával jóváhagyja a cookie-k használatát a Cookie-kkal kapcsolatos irányelv értelmében.",
    "By using our website, you consent to the use of cookies in accordance with the Cookie Policy.",
  ],
  CloseTable: ["Lezárás", "Close"],
  SureCloseTableNotPaidYetText: [
    "Biztosan lezárod az asztalt? Még nem lett teljesen kifizetve a számla.",
    "Sure about closing the table? It's not paid yet completely.",
  ],
  Close: ["Bezárás", "Close"],
  RestaurantClosed: ["Étterem zárva", "Restarant is closed"],
  LoginMetaTitle: ["Blundee Partner Bejelentkezés", "Blundee Partner Login"],
  LoginMetaContent: ["Partneroldal bejelentkezés", "Partner page login"],
  ForgotMyPassword: ["Elfelejtettem a jelszavam", "Forgot my password"],
  SendPasswordResetTitle: ["Jelszóemlékeztető küldése", "Send new password"],
  PasswordResetText: [
    "Kérlek add meg az email címedet. Ha nem emlékeszel rá, írj egy emailt, hogy tudjunk segíteni!",
    "Please enter your email address. If you don’t remember it, write an email to us so we can help out!",
  ],
  Contract: ["Szerződés", "Contract"],
  Contracts: ["Szerződések", "Contracts"],
  PayingCycle: ["Számlázási ciklus", "Payment period"],
  PayoutCycle: ["Kiutalási ciklus", "Payout period"],
  CompanyNameAndVATNumber: ["Cég neve, adószáma", "Name and VAT number of the company"],
  VATNumber: ["Adószám", "VAT Number"],
  NTAKvendeglatoUzletRegSzam: ["NTAK Szolgáltatói regisztrációs szám", "NTAK Registration number"],
  NTAKIntegrationOK: ["Integráció éles.", "Integration is working."],
  NTAKIntegrationWaiting: [
    "Integráció élesítéshez szükségünk van a .cer tanúsítvány fájlra. Kérlek küldd el nekünk.",
    "Integration is missing a .cer certificate file. Please send it to us.",
  ],
  CheckMyContracts: ["Szerződéseim megtekintése", "Check my contracts"],
  ContractContentTitle: ["Blundee.com - Partnerségi Megállapodás", "Blundee.com - Parter Contract"],
  StartContract: ["Szerződéskötés", "Start contract"],
  CreateNewCassaPointIntegrationButtonText: ["Új kapcsolat beállítása", "New CassaPoint Integration"],
  CassaPointDetails: ["CassaPoint integráció adatok:", "CassaPoint integartion details:"],
  CassaPointIntegrationDetails: [
    "Az integráció igényét (és egyben a Kliens nevet) a CassaPointtól kell igényelni, aminek az átfutási ideje 7-10 nap, költsége egyszeri 2.000Ft és további 10Ft/rendelés. A pontos inforációkért kérlek vedd fel a kapcsolatot a ToolSite / CassaPoint üzemeltetőjével.",
    "If you would like to integrate Blundee with ToolSite/CassaPoint, you need to request an integration from ToolSite. The setup takes about 7-10 days of time and the fee is 2000Ft and additional 10Ft per transferred order. For more accurate information on the prices please contact ToolSite/CassaPoint. Blundee takes no fees on this integration.",
  ],
  ClientNameText: ["Kliens név", "Client name"],
  New: ["Új", "New"],
  RemoveNotificationConfirmTitle: ["Értesítés törlése", "Remove notification"],
  RemoveNotificationConfirmDetails: [
    "Biztosan törlöd az értesítési beállítást?", "Are you sure you want to delete this notification setting?",
  ],
  Yes: ["Igen", "Yes"],
  No: ["Nem", "No"],
  NotificationSettingDeletedDetails: [
    "Értesítési csatorna sikeresen törölve!", "Notification setting removed successfully!",
  ],
  LastNameShortDetails: ["Vezetéknév túl rövid!", "The given last name is too short."],
  FirstNameShortDetails: ["Keresztnév túl rövid!", "The given first name is too short."],
  FirstNameNotValid: ["Keresztnév helytelen!", "The given first name is not valid."],
  LastNameNotValid: ["Vezetéknév helytelen!", "The given last name is not valid."],
  PinNotValid: ["PIN kód helytelen!", "The given PIN is not valid."],
  Notification: ["Értesítés", "Notification"],
  Notifications: ["Értesítések", "Notifications"],
  NotificationsDetails: [
    "Itt tudod néhány lépéssel beállítani, hogy az Blundee milyen felületeken küldjön értesítéseket számodra.",
    "You can set up how you would like us to notify you.",
  ],
  ServerNotAvailable: ["Szerver nem elérhető.", "Server not available."],
  EmailOrPasswordIsWrong: ["Hibás email cím vagy jelszó.", "Email or password is wrong."],
  MODIFY_USERS: ["Felhasználók és hozzáférések módosítása", "Modify users and permissions"],
  MODIFY_MENU: ["Étlap, kategóriák, receptek, termékek módosítása", "Modify menu, categories, recipes, products"],
  MODIFY_STORAGES: [
    "Raktár beállítások módosítása (raktár törlés, hozzáadás, átnevezés)", "Modify storages (delete, modify, add new)",
  ],
  MOVE_STORAGE: ["Termékek raktármozgatása", "Move product quantities between storages"],
  REQUEST_STORAGE_MOVEMENT: ["Raktármozgatás kérése", "Create a storage movement request"],
  STORAGE_INWARD_HANDLE: ["Bevételezés és gyártás", "Inward handling and manufacturing"],
  STOCK_TAKING: ["Leltározás", "Stock taking"],
  MODIFY_TABLES: ["Asztalok módosítása", "Change layout"],
  MODIFY_DISCOUNTS: ["Kuponok szerkesztése", "Modify discounts and coupons"],
  MODIFY_CRM: ["CRM módosítása (vendégek)", "Modify CRM (guest details)"],
  MODIFY_SETTINGS: ["Üzletbeállítások módosítása", "Modify core shop settings"],
  MODIFY_OPENING_HOURS: ["Nyitvatartás módosítása", "Modify opening hours"],
  STORNO_ORDER: ["Rendelések sztornózása", "Storno orders"],
};

export default class Language {
  static getName(nameArray: string[] | string[][], subIndex: number | undefined): string {
    if (nameArray === undefined || nameArray === null) {
      return undefined;
    }

    let value: any = nameArray[ContextSystem.language];
    if (!value) {
      value = nameArray[0];
    }

    if (subIndex !== undefined && subIndex !== null) {
      return value[subIndex];
    }

    return value;
  }
}
