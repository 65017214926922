import React, { Component } from "react";
import Modal from "./Modal";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";
import styled from "styled-components";
import { Element, ElementTypes } from "../../model/BluePrint";
import Language, { Names } from "../../utils/Language";
import TablesLayout from "./TablesLayout";
import { Button } from "../FormComponents";
import { TabBar, TabItem } from "../home/Orders";
import AddressPicker from "../home/AddressPicker";
import { Address } from "../../model/Address";
import type { ShippingMethod } from "../../model/ShippingPrice";
import { ShippingMethods } from "../../model/ShippingPrice";
import { UpsellRule, UpsellTypes } from "../../model/UpsellRule";
import { Product, ProductTypes } from "../../model/Product";
import StandingComponent, { List, ScrollWrapper } from "../home/StandingComponent";
import type { PaymentMethodType } from "../../model/PaymentMethodSetting";
import { PaymentMethodTypes } from "../../model/PaymentMethodSetting";
import CartCalc from "../../utils/CartCalc";
import { Order, OrderExtra, OrderProduct } from "../../model/Order";
import { Shop } from "../../model/Shop";

const Wrapper = styled.div`
  width: 100%;

  flex-grow: 2;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const AddressPickerWrapper = styled.div`
  width: 96%;
  height: 380px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;

  flex-grow: 2;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  margin-top: 6px;
`;

const NoTables = styled.h3`
  font-weight: normal;
  font-family: Arial, sans-serif;
  font-size: 12pt;
  text-align: center;
  width: 100%;
  padding: 24px;
`;

export default class TableSelector extends Component {
  state: {
    language: number,
    showModal: boolean,
    tables: Element[],
    tableSelected: Element,
    cb: ()=>{},
    selectedType: ShippingMethod,
    paymentMethod: PaymentMethodType,
    address: Address,
    products: Product[],
    upsellRules: UpsellRule[],
    cart: Product[],
    modifyingOrder: Order,
    origin: string,
    shop: Shop,
  } = {
    language: ContextSystem.language,
    showModal: false,
    tables: [],
    tableSelected: undefined,
    cb: undefined,
    selectedType: ShippingMethods.AT_PLACE,
    paymentMethod: PaymentMethodTypes.ANY,
    address: undefined,
    products: [],
    upsellRules: [],
    cart: [],
    modifyingOrder: undefined,
    origin: undefined,
    shop: ContextSystem.selectedShop
  };

  changeSelectedType(type: number) {
    this.setState({ selectedType: type });
  }

  loadElements() {
    this.setState({
      shop: ContextSystem.selectedShop,
      tables: ContextSystem.elements.filter(
        e => e.enabled && e.partnerID === ContextSystem?.selectedShop?.id && e.type === ElementTypes.TABLE),
      products: ContextSystem.products.filter(p => p.enabled && p.partnerID === ContextSystem?.selectedShop?.id),
      upsellRules: ContextSystem.upsellRules.filter(
        p => p.enabled && p.shopIDs.find(sid => sid.modelID === ContextSystem?.selectedShop?.id))
    });
  }

  componentDidMount() {
    this.loadElements();

    EventSystem.subscribe(EventSystem.events.open_table_selector, data => {
      let { tableSelected, cb, cart, modifyingOrder, shippingMethod, origin, doneClicked }: {
        tableSelected: Element,
        shippingMethod: number,
        origin: string,
        doneClicked: boolean,
        cb: ({
               tableSelected: Element,
               shippingMethod: ShippingMethod,
               address: Address,
               cancelled: boolean, //was order cancelled? if yes, let them know to not proceed with the process
               cart: Product[]
             })=>{},
        cart: Product[],
        modifyingOrder: Order,
      } = data;

      if ( //this popup window doesn't add any value, then return
        (doneClicked && origin === "orderEditor" && tableSelected !== undefined) ||
        (
          modifyingOrder
          && TableSelector.filterUpsellRules(this.state.upsellRules, modifyingOrder.shippingMethod,
            modifyingOrder.paymentMethod, cart
          ).length <= 0
        )
      ) {
        cb && cb({
          tableSelected,
          shippingMethod: modifyingOrder?.shippingMethod ?? shippingMethod,
          address: modifyingOrder?.address ?? "",
          cancelled: false, //skip this step
          cart
        });
        return;
      }

      let defaultType = ShippingMethods.AT_PLACE;
      if (!this.state.shop?.enableTableOrder)
        defaultType = ShippingMethods.PICKUP;
      if (!this.state.shop?.enablePersonalOrder)
        defaultType = ShippingMethods.DELIVERY;
      if (!this.state.shop?.enableDeliveryOrder)
        defaultType = ShippingMethods.AT_PLACE;

      this.setState({
        showModal: true,
        tableSelected,
        cb,
        cart,
        modifyingOrder,
        selectedType: shippingMethod ?? defaultType,
        origin
      });
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({ language, elements, products, upsellRules, selectedShop }) => {
        if (language !== undefined)
          this.setState({ language });
        if (elements !== undefined || products !== undefined || upsellRules !== undefined || selectedShop !== undefined)
          this.loadElements();
      });
  }

  send(): void {
    if (this.state.address && this.state.address.id < 0) {
      //save address and get ID
    }

    this.state.cb && this.state.cb({
      tableSelected: this.state.tableSelected,
      shippingMethod: this.state.selectedType,
      address: this.state.address,
      cancelled: false,
      cart: this.state.cart
    });
    this.setState({
      showModal: false
    });
  }

  selectTable(t: Element) {
    this.setState({
      tableSelected: t
    }, () => {
      //deselect
      if (t === undefined) {
        this.send();
      }
    });
  }

  cancel() {
    this.setState({
      order: undefined,
      showModal: false
    });
    this.state.cb && this.state.cb({ cancelled: true });
  }

  addressSelected(address: Address) {
    this.setState({ address });
  }

  static filterUpsellRules(
    upsellRules: UpsellRule[], shippingMode: ShippingMethod, paymentMethod: PaymentMethodType,
    cart: Product[]
  ): UpsellRule[] {
    return upsellRules?.filter(upsellRule => {
      return !(
        (!upsellRule.productIDs || upsellRule.productIDs.length <= 0)
        || (upsellRule.type !== UpsellTypes.POS_AT_END_OF_ORDER)
        || (!upsellRule.shopIDs.find(sid => sid.modelID === ContextSystem.selectedShop?.id))
        || (
          upsellRule.productsTriggering && upsellRule.productsTriggering.length > 0
          && !upsellRule.productsTriggering.find(pid => cart.find(p => p.id === pid.modelID) !== undefined)
        )
        || (shippingMode !== ShippingMethods.ANY && upsellRule.shippingMode !== ShippingMethods.ANY && upsellRule.shippingMode !== shippingMode)
        || (paymentMethod !== PaymentMethodTypes.ANY && upsellRule.paymentMode !== PaymentMethodTypes.ANY && upsellRule.paymentMode !== paymentMethod)
      );
    });
  }

  static renderUpsellItems(
    upsellRules: UpsellRule[], cart: Product[], shippingMode: ShippingMethod, paymentMethod: PaymentMethodType,
    products: Product[], handleProductClick: ()=>{}, increaseProductCart: (p: Product, k: number)=>{}
  ) {
    upsellRules = TableSelector.filterUpsellRules(upsellRules, shippingMode, paymentMethod, cart);
    return (
      <ScrollWrapper vertical={true}>
        <List>
          {upsellRules.map((upsellRule, i) => {
            return (
              <React.Fragment key={i}>
                {upsellRule.productIDs.map((pid, j) => {
                  let p: Product = products.find(prod => prod.id === pid.modelID);
                  let qty: number = CartCalc.getProductQuantity(p, cart);
                  return StandingComponent.renderProduct(
                    p, null, j, null,
                    false, (p) => handleProductClick && handleProductClick(p),
                    true, (p, k) => increaseProductCart && increaseProductCart(p, k), qty
                  );
                })}
              </React.Fragment>
            );
          })}
        </List>
      </ScrollWrapper>
    );
  }

  increaseProductCartClick(product: Product, qty: number) {
    let cart = this.getCart();
    cart = CartCalc.increaseQuantity(product, cart, qty);
    this.setState({ cart });
  }

  handleProductClick(product: Product) {
    if (!product)
      return;

    if (product.extraGroups.length > 0 || product.versions.length > 0) {
      EventSystem.publish(EventSystem.events.open_extras_selector, {
        product, onFinish: (p: Product) => {
          let leadOP: OrderProduct = CartCalc.getLeadOrderProduct(p.orderProduct);

          leadOP.extrasPrice = CartCalc.getOrderProductExtrasPrice(leadOP, p);
          leadOP.originalExtrasPrice = leadOP.extrasPrice;
          leadOP.itemTotalPrice = leadOP.qty * (leadOP.price + leadOP.extrasPrice);
          leadOP.originalItemTotalPrice = leadOP.itemTotalPrice;
          for (let extra: OrderExtra of leadOP.extras) {
            extra.originalTotal = extra.total;
            extra.originalPrice = extra.price;
          }

          this.addProductToCart(p);
        }
      });
    } else {
      this.addProductToCart(product);
    }
  }

  addProductToCart(product: Product) {
    if (!product)
      return;

    product = { ...product };

    let leadOP: OrderProduct = CartCalc.getLeadOrderProduct(product.orderProduct);
    leadOP.qty = 1;
    if (product.type === ProductTypes.PRODUCT)
      this.addProduct(product);
    else if (product.type === ProductTypes.MENU)
      this.addMenu(product);
  }

  addProduct(product: Product) {
    let cart: Product[] = this.getCart();
    cart = CartCalc.addProduct(product, cart);
    this.setState({ cart });
  }

  getCart(): Product[] {
    return JSON.parse(JSON.stringify(this.state.cart));
  }

  addMenu(menu: Product) {
    let cart: Product[] = this.getCart();
    cart = CartCalc.addMenu(menu, cart);
    this.setState({ cart });
  }

  render() {
    let showTabs: boolean = !this.state.modifyingOrder && this.state.origin !== "assignTableButton";
    let shop: Shop = this.state.shop;

    return (
      <Modal size={"lg"} show={this.state.showModal}
             onEscapeKeyDown={() => this.cancel()}
      >
        <Modal.Body padding={"6px"}>
          <Wrapper>
            {showTabs && //if we're changing an existing order, then we cannot modify the shipping mode, table and address
              <>
                <TabBar>
                  {shop?.enableTableOrder &&
                    <TabItem
                      selected={this.state.selectedType === ShippingMethods.AT_PLACE}
                      onClick={() => this.changeSelectedType(ShippingMethods.AT_PLACE)}
                    >
                      {Language.getName(Names.AtPlace)}
                    </TabItem>
                  }
                  {shop?.enableDeliveryOrder &&
                    <TabItem
                      selected={this.state.selectedType === ShippingMethods.DELIVERY}
                      onClick={() => this.changeSelectedType(ShippingMethods.DELIVERY)}
                    >
                      {Language.getName(Names.Delivery)}
                    </TabItem>
                  }
                  {shop?.enablePersonalOrder &&
                    <TabItem
                      selected={this.state.selectedType === ShippingMethods.PICKUP}
                      onClick={() => this.changeSelectedType(ShippingMethods.PICKUP)}
                    >
                      {Language.getName(Names.TakeAway)}
                    </TabItem>
                  }
                </TabBar>
              </>
            }

            <ContentWrapper>
              {this.state.modifyingOrder &&
                <>
                  {TableSelector.renderUpsellItems(this.state.upsellRules, this.state.cart, this.state.selectedType,
                    this.state.paymentMethod, this.state.products, (p) => this.handleProductClick(p),
                    (p, i) => this.increaseProductCartClick(p, i)
                  )}
                </>
              }
              {!this.state.modifyingOrder &&
                <>
                  {this.state.selectedType === ShippingMethods.AT_PLACE &&
                    <>
                      <TablesLayout
                        onZoneSelected={() => {
                        }}
                        onTableSelected={(table: Element) => this.selectTable(table)}
                        filterTable={() => true}
                        selectedTable={this.state.tableSelected}
                        emptyNote={<NoTables>{Language.getName(Names.NoTablesToSelectFrom)}</NoTables>}
                        useFixDate={undefined}
                      />
                      <Button bg_color={this.state.tableSelected ? "white" : "#3b6499"}
                              color={this.state.tableSelected ? "#3b6499" : "white"}
                              onClick={() => this.selectTable(undefined)}
                      >
                        {Language.getName(Names.DeselectTable)}
                      </Button>
                    </>
                  }
                  {this.state.selectedType === ShippingMethods.DELIVERY &&
                    <>
                      <AddressPickerWrapper>
                        <AddressPicker cb={({ address }) => this.addressSelected(address)} address={this.state.address}
                                       callerID={ContextSystem.getNextUniqueID()} />
                      </AddressPickerWrapper>
                      {TableSelector.renderUpsellItems(this.state.upsellRules, this.state.cart, this.state.selectedType,
                        this.state.paymentMethod, this.state.products, (p) => this.handleProductClick(p),
                        (p, i) => this.increaseProductCartClick(p, i)
                      )}
                    </>
                  }
                  {this.state.selectedType === ShippingMethods.PICKUP &&
                    <>
                      {Language.getName(Names.NoMoreDetailsNeeded)}
                      {TableSelector.renderUpsellItems(this.state.upsellRules, this.state.cart, this.state.selectedType,
                        this.state.paymentMethod, this.state.products, (p) => this.handleProductClick(p),
                        (p, i) => this.increaseProductCartClick(p, i)
                      )}
                    </>
                  }
                </>
              }
            </ContentWrapper>
          </Wrapper>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => this.cancel()}>
            {Language.getName(Names.CancelButtonText)}
          </Button>
          <Button onClick={() => this.send()}>
            {Language.getName(Names.Save)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
