import React, { Component } from "react";
import styled, { css } from "styled-components";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";
import Language, { Names } from "../../utils/Language";
import { Input } from "../../pages/LoginPage";
import {
  AiOutlineEdit,
  BsPencilSquare,
  BsPlus,
  FaCommentDots,
  FaEye,
  FaEyeSlash,
  FaRegCommentDots,
  FaRegSave,
  FaSave,
  FaTimes,
  FaTrashAlt,
} from "react-icons/all";
import {
  Category,
  CategoryViewTypes,
  Product,
  ProductTypes,
  ProductView,
  TranslatableString,
} from "../../model/Product";
import { Shop } from "../../model/Shop";
import Orders, { ProductType } from "./Orders";
import { getOpeningState } from "../../utils/HoursCalc";
import { HourStatuses } from "../../model/Hour";
import { toast } from "react-toastify";
import CartCalc from "../../utils/CartCalc";
import CartContent from "../CartContent";
import { Button as ButtonOrg, Textarea } from "../FormComponents";
import type { OptionValue } from "../modals/InwardHandlingModal";
import { ShippingMethods } from "../../model/ShippingPrice";
import ConfirmationModal from "../modals/ConfirmationModal";
import {
  Order,
  OrderExtra,
  OrderPayment,
  OrderProduct,
  OrderState,
  OrderUsedCouponID,
  TableReservation,
} from "../../model/Order";
import type { PutPaymentType, SendOrderMessageType } from "../../utils/api/OrdersAPI";
import { OrdersAPI } from "../../utils/api/OrdersAPI";
import { Coupon, CouponModelTypes, CouponTypes } from "../../model/Coupon";
import { Profile } from "../../model/Profile";
import ErrorMessage from "../../utils/api/ErrorMessages";
import { Storage, StorageTypes } from "../../model/Stock";
import type { Option } from "../modals/OrderStateCommentEditor";
import Select2 from "react-select";
import { ProductsAPI } from "../../utils/api/ProductsAPI";
import { ShopProfile, ShopProfileTypes } from "../../model/ShopProfile";
import { HeaderButton } from "./Products";
import Config from "../../utils/Config";

export const ButtonTag = styled.div`
  width: ${({ width }) => width ?? "15px"};
  height: ${({ height }) => height ?? "15px"};
  background-color: ${({ background_color }) => background_color ?? "white"};
  box-shadow: 0 0 3px 0 black;
  font-size: 9pt;
  font-weight: bold;
  color: #3e3e3e;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  position: absolute;
  border-radius: 100px;

  top: ${({ top }) => top ?? "unset"};
  right: ${({ right }) => right ?? "unset"};
  bottom: ${({ bottom }) => bottom ?? "unset"};
  left: ${({ left }) => left ?? "unset"};
`;

export const Button = styled(ButtonOrg)`
  position: relative;

  width: ${({ width }) => width ?? "130px"};
  margin: 5px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height ?? "48px"};

  &:hover {
    background-color: ${({ bg_color2 }) => bg_color2 ? bg_color2 : "white"};
    ${({ color2 }) => color2 && css`
      color: ${color2};
    `}
  }

  &:active {
    background-color: ${({ bg_color3 }) => bg_color3 ? bg_color3 : "white"};
    ${({ color3 }) => color3 && css`
      color: ${color3};
    `}
  }

  @media screen and (max-width: 1000px) {
    font-size: 9pt;
    width: ${({ m_width }) => m_width ?? "100px"};
    margin: 0 3px;
    padding: 8px 10px;
    height: 35px;
  }

  ${({ disabled }) => disabled === true && css`
    background-color: #666666;
    cursor: not-allowed;
  `}
`;

export const CategoryDiv = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: ${({ max_width }) => max_width ?? "150px"};
  min-width: 60px;
  min-height: ${({ min_height }) => min_height ?? "unset"};

  background-color: ${({ bg_color, selected }) => selected ? "#e3e3e3" : bg_color ?? "white"};
  border-radius: 5px;
  box-shadow: 0 0 4px 0 #444440;
  border: none;
  font-size: 10pt;

  padding: 6px 6px;
  margin: 3px 6px;
  user-select: contain;

  outline: none;

  transition: box-shadow 100ms ease-in-out;

  cursor: ${({ is_disabled }) => is_disabled === true ? "default" : "pointer"};

  &:hover, &:active, &:focus {
    outline: none;
  }

  &:hover {
    ${({ is_disabled }) => is_disabled !== true && css`
      box-shadow: 0 0 7px 0 #444440;
    `}
  }

  &:active {
    ${({ is_disabled }) => is_disabled !== true && css`
      box-shadow: 0 0 2px 0 #444440;
    `}
  }

  & > div {
    margin: 0;
  }

  & > div:nth-child(2) {
    font-size: 9pt;
  }
`;

export const ProductImage = styled.img`
  width: 100%;
  height: calc(130px / 16 * 9);
  object-fit: cover;
`;

export const ProductDetails = styled.div`
  width: 100%;
  padding: 6px;
  font-size: 10pt;
  border-top: 1px solid #dcdcdc;
`;

export const ProductQtyDiv = styled.div`
  padding: 3px 6px;
  flex-grow: 1;
`;

// const ProductVersion = styled.div`
//   background-color: #efefef;
//   padding: 1px 2px;
//   border-radius: 3px;
//   font-size: 8pt;
//   margin: 4px 0;
// `;

export const ProductDiv = styled(CategoryDiv)`
  padding: 0;
  width: 130px;
  //height: 110px;
  position: relative;
  overflow: hidden;
  justify-content: flex-start;
  margin: 6px;

  ${({ is_disabled }) => is_disabled === true && css`
    background-color: #e2e2e2;
    color: #6a6a6a;
  `};

  @media screen and (max-width: 1000px) or (min-height: 501px) {
    font-size: 9pt;
  }
`;

const Wrapper = styled.div`
  padding: ${({ padding }) => padding ?? "3px 10px 5px 10px"};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: white;

  @media screen and (max-width: 1000px) {
    padding: 3px 4px 2px 4px;
  }
`;

const RowWrapper = styled.div`
  width: 100%;
  height: ${({ height }) => height ?? "20%"};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: ${({ justify_content }) => justify_content ?? "flex-start"};
  ${({ grow }) => grow === true && css`
    flex-grow: 2;
  `};

  @media screen and (max-width: 1000px) {
    height: ${({ m_height, height }) => m_height ?? height ?? "20%"};
  }
`;

const MenuContainer = styled.div`
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const RightMenu = styled.div`
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const CartContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const PaymentsWrapper = styled.div`
  width: 300px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  //flex-wrap: wrap;
  align-items: flex-end;
  margin: 0 6px 0 0;
  overflow-y: auto;
  height: 100%;
`;

export const PaymentOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  flex-shrink: 0;
  margin: 1px 0;
  font-size: 10pt;

  //border-radius: 5px;
  //box-shadow: 0 0 4px 0 #444440;
`;

export const PaymentInput = styled(Input)`
  width: 50px;
  min-height: auto;
  margin: 0 5px;
  padding: 5px 5px;
`;

export const PaymentName = styled.div`
  width: 100%;
  text-align: left;

  & > svg {
    margin-right: 5px;
    font-size: 12pt;
    color: green;
  }
`;

const OrderCommentsSwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin: 0 6px;
  font-size: 21pt;
  user-select: none;
  cursor: pointer;
`;

const ActionsLeft = styled.div`
  justify-self: flex-start;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: auto;
`;

const ControlsWrapper = styled.div`
  display: flex;
  height: fit-content;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: end;
  padding-top: 6px;
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  width: 100%;
  margin: 0;
`;

const TotalRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  font-size: ${({ font_size }) => font_size};
  margin-top: 2px;

  ${({ color }) => color && css`
    color: ${color}
  `};

  & > span {
    display: block;

    & > svg {
      padding: 4px;
      cursor: pointer;
      font-size: 20pt;
    }
  }
`;

const TotalDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  padding: 6px;

  border-radius: 10px;
  background-color: #efefef;

  margin: 12px 0;
  font-size: 18pt;
  font-weight: bold;
  color: #0b0b1a;
  width: 100%;
  height: fit-content;

  @media screen and (max-width: 1000px) {
    font-size: 14pt;
    margin: 6px 0;
  }
`;

const OrderCommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  padding: 6px;

  margin: 12px 0 0 0;
  font-size: 14pt;
  font-weight: bold;
  color: #0b0b1a;
  width: 100%;
  height: fit-content;

  @media screen and (max-width: 1000px) {
    font-size: 10pt;
    margin: 6px 0;
  }
`;

const FiltersLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: fit-content;
  height: 100%;
`;

const FiltersWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;

  @media screen and (max-width: 1000px) {
    width: 150px;
    margin: 0;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  min-height: 65px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  box-shadow: 0 4px 2px -2px darkgrey;
  margin: 0 0 6px 0;

  & > ${RowWrapper} {
    @media screen and (max-width: 800px) {
      //flex-wrap: wrap;
    }
  }
`;

const ClearSearchWrapper = styled.div`
  margin: 0 0 0 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > svg {
    font-size: 14pt;
    cursor: pointer;
    color: #444440;
  }
`;

const ProductsWrapper = styled.div`
  min-height: 60%;
  width: 100%;
  flex-grow: 2;
  position: relative;

  overflow-y: auto;
`;

export const ScrollWrapper = styled.div`
  width: 100%;
  height: 100%;

  ${({ vertical }) => vertical === true && css`
    overflow-x: hidden;
    overflow-y: auto;
  `};

  ${({ vertical }) => vertical !== true && css`
    overflow-x: auto;
    overflow-y: hidden;
  `};
`;

export const List = styled.div`
  flex-grow: 2;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;

  ${({ scroll }) => scroll === true && css`
    flex-wrap: nowrap;
    width: max-content;
  `};
`;

// noinspection JSUnusedLocalSymbols
const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-wrap: wrap;
  margin-bottom: 6px;
  z-index: 2;

  & > h2 {
    width: 100%;
    font-size: 14pt;
    font-weight: normal;
    margin: 0;
  }

  & > svg {
    margin-right: 12px;
    font-size: 14pt;
    color: #444440;
    cursor: pointer;
  }

  & > div {
    cursor: pointer;
    overflow: hidden;
    flex-grow: 2;

    & > p {
      max-lines: 1;
      text-overflow-ellipsis: "...";
      margin: 0;
      padding: 0;
    }
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

const CategoriesScroll = styled.div`
  width: 100%;
  display: block;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 1px;
  margin-bottom: 1px;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    background-color: #c9c9c9;
    border-radius: 100px;
  }
`;

const CategoriesWrapper = styled.div`
  margin-top: 6px;

  flex-shrink: 0;
  flex-grow: 2;
  max-height: 65px;
  max-width: calc(100% - 150px);

  height: 100%;
  width: fit-content;
`;

const ModifyingOrderNumber = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 6px 12px;

  color: #333333;
  font-size: 10pt;

  & > svg {
    font-size: 12pt;
    margin: 0 6px;
  }

  & > span {
    font-weight: bold;
    margin: 0 0 0 6px;
  }
`;

const CounterSelect = styled(Select2)`
  width: 120px;
  margin-right: 5px;
  font-size: 12px;
`;

export const ProductQtyEditor = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ProductPlusMinusButton = styled(HeaderButton)`
  padding: 3px 6px;
  font-size: 12pt;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

export const HideUnhideButton = styled.div`
  margin-top: 6px;
  outline: 0;
  background-color: ${({ hidden }) => hidden ? "#79a2d5" : "#e5e5e5"};
  padding: 2px 4px;
  border-radius: 3px;
  border: none;
  cursor: pointer;

  transition: background-color 200ms ease-in-out;

  // &:hover {
    //   background-color: ${({ hidden }) => hidden ? "#accbf5" : "#c9c9c9"};
  // }
  //
  // &:active {
    //   background-color: ${({ hidden }) => hidden ? "#4e78b0" : "#f1f1f1"};
  // }
`;

export const ProductRenderModes: { [keys: string]: number } = {
  ORDER: 0,
  HIDE_UNHIDE: 1,
};

export type ProductRenderMode = $Values<typeof ProductRenderModes>;

export default class StandingComponent extends Component<{
  origin: string,
  selectTable: boolean,
  padding: string,
  order: Order,
  showOrdersButton: boolean,
  onDone: ()=>{},
  tableSelected: Element
}> {
  eventIDs: number[] = [];
  state: {
    origin: string,
    padding: string,
    showOrdersButton: boolean,
    orders: Order[],
    storages: Storage[],
    selectedCounter: Option,
    mode: number, //ProductRenderModes

    language: number,
    selectTable: boolean,
    tableSelected: Element,
    search: string,
    selectedCategory: Category,
    categories: Category[],
    products: Product[],
    paymentMethod: number,
    selectedShop: Shop,
    cart: Product[],
    newPayments: OrderPayment[],
    loading: boolean,
    timeout: boolean,
    doneClicked: boolean,
    selectedCoupons: Coupon[],
    modifyingOrder: Order,
    profile: Profile,
    shopProfile: ShopProfile,
    orderComment: string,
    shippingMethod: number,
    productViews: ProductView[],
    showCommentsInOrdering: number,
    defaultCategory: number,
  } = {
    padding: "3px 10px 5px 10px",
    showOrdersButton: true,
    orders: [],
    storages: [],
    selectedCounter: { value: -1, label: Language.getName(Names.DefaultStorage) },
    mode: 0,

    language: ContextSystem.language,
    selectTable: false,
    tableSelected: undefined,
    search: "",
    selectedCategory: undefined,
    categories: [],
    selectedShop: [],
    paymentMethod: -1,
    products: [],
    cart: [],
    newPayments: [],
    loading: false,
    timeout: false,
    doneClicked: false,
    selectedCoupons: [],
    modifyingOrder: undefined,
    profile: undefined,
    shopProfile: ContextSystem.profile,
    orderComment: "",
    shippingMethod: ShippingMethods.AT_PLACE,
    productViews: [],
    showCommentsInOrdering: ContextSystem.showCommentsInOrdering,
    defaultCategory: -1,
  };

  sortProducts(products: Product[]): Product[] {
    if (this.state.search?.length > 0) {
      let s: string = this.state.search.toLowerCase();
      return products.sort((p1, p2) => {
        let p1CloseMatch: boolean = CartCalc.stringHasSameStartingWord(TranslatableString.get(p1.name), s);
        let p2CloseMatch: boolean = CartCalc.stringHasSameStartingWord(TranslatableString.get(p2.name), s);
        if (p1CloseMatch === p2CloseMatch) {
          return p1.orderPlace - p2.orderPlace;
        } else {
          return p1CloseMatch ? -1 : +1;
        }
      });
    } else {
      let ctxsys = ContextSystem;
      return products.sort((p1, p2) => {
        let c1: Category = p1.categoryID === 0 ? {
          id: 0,
          orderNumber: 0,
        } : ctxsys.categories.find(cat => cat.id === p1.categoryID);
        let c2: Category = p2.categoryID === 0 ? {
          id: 0,
          orderNumber: 0,
        } : ctxsys.categories.find(cat => cat.id === p2.categoryID);

        if (!c1 || !c2)
          return 0;

        if (!p1.active && p2.active)
          return +1;
        else if (p1.active && !p2.active)
          return -1;

        //they are the same categories
        if (c1.id === c2.id)
          return p1.orderPlace - p2.orderPlace;

        //both are children, have parents --> order based on their parents ordernumber
        if (c1.parent > 0 && c2.parent > 0 && c1.id > 0 && c2.id > 0) {
          let c1Parent: Category = c1.parent > 0 && c1.id > 0 ? ctxsys.categories.find(cat => cat.id === c1.parent) : {
            id: 0,
            orderNumber: -1,
          };
          let c2Parent: Category = c2.parent > 0 && c2.id > 0 ? ctxsys.categories.find(cat => cat.id === c2.parent) : {
            id: 0,
            orderNumber: -1,
          };

          //they have the same parents
          if (c1Parent.id === c2Parent.id)
            return c1.orderNumber - c2.orderNumber;

          return c1Parent?.orderNumber ?? 0 - c2Parent?.orderNumber ?? 0;
        }

        //c1 has parent, c2 is not
        if (c1.parent > 0 && c1.id > 0) {
          let c1Parent: Category = c1.parent > 0 && c1.id > 0 ? ctxsys.categories.find(cat => cat.id === c1.parent) : {
            id: 0,
            orderNumber: -1,
          };
          return c1Parent?.orderNumber ?? 0 - c2.orderNumber;
        }

        //c2 has parent, c1 is not
        if (c2.parent > 0 && c2.id > 0) {
          let c2Parent: Category = c2.parent > 0 && c2.id > 0 ? ctxsys.categories.find(cat => cat.id === c2.parent) : {
            id: 0,
            orderNumber: -1,
          };
          return c1.orderNumber - c2Parent?.orderNumber ?? 0;
        }

        //both are parents
        return c1.orderNumber - c2.orderNumber;
      });
    }
  }

  loadContext() {
    let ctxsys = ContextSystem;

    let storages: Storage[] = ctxsys.storages.filter(e => e.enabled && e.shopIDs.includes(ctxsys.selectedShop?.id));
    let productViews: ProductView[] = ctxsys.productViews;

    let categories: Category[] = ctxsys.categories.filter(c => c.partnerID === ctxsys.selectedShop?.id
      && [CategoryViewTypes.PRIVATE_VIEW, CategoryViewTypes.NORMAL_CATEGORY, CategoryViewTypes.PUBLIC_VIEW].includes(c.view))
      .sort((c1, c2) =>
        c1.view === c2.view
        ? c2.parent - c1.parent === 0
          ? c1.orderNumber - c2.orderNumber
          : c1.parent - c2.parent
        : c1.view - c2.view,
      );

    let selectedCounter: OptionValue = { label: Language.getName(Names.DefaultStorage), value: -1 };
    if (ctxsys.selectedCounter > 0)
      selectedCounter = {
        value: ctxsys.selectedCounter,
        label: ctxsys.storages.find((st: Storage) => st.id === ctxsys.selectedCounter)?.name ?? "-",
      };

    let products: Product[] = ctxsys.products.filter(c => c.partnerID === ctxsys.selectedShop?.id);
    products = this.sortProducts(products);

    let defaultCategory: number = this.getDefaultCategory();

    this.setState({
      categories,
      selectedShop: ctxsys.selectedShop,
      orders: ctxsys.orders.filter(o => o.enabled && o.partnerID === ctxsys.selectedShop?.id),
      products,
      storages,
      productViews,
      selectedCounter,
      shopProfile: ctxsys.profile,
      showCommentsInOrdering: ctxsys.showCommentsInOrdering,
      defaultCategory,
    });
  }

  getDefaultCategory() {
    let defaultCategoryS: string = window.localStorage.getItem("defaultCategory");
    return defaultCategoryS ? parseInt(defaultCategoryS) : -1;
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
  }

  // noinspection JSUnresolvedVariable
  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    this.loadProps();
  }

  loadProps() {
    if (this.state.selectTable !== this.props.selectTable)
      this.setState({ selectTable: this.props.selectTable });
    if (this.state.modifyingOrder !== this.props.order) {
      this.editOrder(this.props.order);
    }
    if (this.state.padding !== this.props.padding)
      this.setState({ padding: this.props.padding });
    if (this.state.origin !== this.props.origin)
      this.setState({ origin: this.props.origin });
    if (this.state.showOrdersButton !== this.props.showOrdersButton)
      this.setState({ showOrdersButton: this.props.showOrdersButton });
    if (this.props.tableSelected && this.state.tableSelected !== this.props.tableSelected) {
      this.setState({ tableSelected: this.props.tableSelected });
    }
  }

  editOrder(order: Order) {
    if (!order)
      return;

    let originalCart: Product[] = CartCalc.createCartFromOrder(order);
    this.setState({
      modifyingOrder: order,
      cart: originalCart,
    });
    this.addTableToEditingOrder(order);
    this.addCouponsToEditingOrder(order);
  }

  addCouponsToEditingOrder(order: Order) {
    if (!order)
      return;
    if (order.usedCoupons === undefined || order.usedCoupons.length <= 0)
      return;

    let coupons: Coupon[] = [];
    for (let c: OrderUsedCouponID of order.usedCoupons) {
      if (!c)
        continue;

      //already added
      if (coupons.find(cou => cou.id === c.usedCouponID) !== undefined)
        continue;

      //check if really used
      if (c.modelType === CouponModelTypes.PRODUCT) {
        let allOrderProducts: OrderProduct[] = [];
        Orders.sortProducts(order.productList).forEach(op => allOrderProducts.push(op));
        order.subOrders.forEach(so => Orders.sortProducts(so.productList).forEach(op => allOrderProducts.push(op)));

        let sumQty = 0;
        allOrderProducts.forEach(op => {
          if (op.productID !== c.modelID)
            return;

          //order ID match or qty is < 0 (remove qty in subOrders)
          if (op.orderID === c.orderID || op.qty < 0)
            sumQty += op.qty;
        });
        if (sumQty !== 0)
          continue;
      }

      let coupon: Coupon = ContextSystem.coupons.find(coupon => coupon.id === c.usedCouponID);
      if (!coupon)
        continue;
      coupons.push(coupon);
    }

    this.setUsedCoupons(coupons);
  }

  addTableToEditingOrder(order: Order) {
    if (!order)
      return;

    if (order.tableReservationID <= 0)
      return;

    let reservation: TableReservation = ContextSystem.tableReservations.find(tr => tr.id === order.tableReservationID);
    if (!reservation)
      return;

    let table: Element = ContextSystem.elements.find(e => e.id === reservation.tableID);
    if (!table)
      return;

    this.setState({ tableSelected: table });
  }

  addProductOrMenuToCart(product: Product) {
    if (!product)
      return;

    product = { ...product };

    let leadOP: OrderProduct = CartCalc.getLeadOrderProduct(product.orderProduct);
    leadOP.qty = 1;
    if (product.type === ProductTypes.PRODUCT)
      this.addProductToCart(product);
    else if (product.type === ProductTypes.MENU)
      this.addMenuToCart(product);
  }

  handleRemoveItem(product: Product) {
    if (!product)
      return;
    let cart: Product[] = this.getCart();
    if (product.type === ProductType.PRODUCT) {
      cart = CartCalc.removeProduct(product, cart);
    } else if (product.type === ProductType.MENU) {
      cart = CartCalc.removeMenu(product, cart);
    }
    this.setState({ cart });
  }

  setNote(product: Product, value: string) {
    if (!product)
      return;
    if (!value)
      value = "";

    let cart: Product[] = this.getCart();
    let cart2: Product[] = [];

    cart.forEach(p => {
      if (p.id === product.id) {
        let pc: Product = { ...p };
        let leadOP: OrderProduct = CartCalc.getLeadOrderProduct(pc.orderProduct);
        leadOP.note = value;
        cart2.push(pc);
      } else {
        cart2.push({ ...p });
      }
    });
    this.setState({ cart: cart2 });
  }

  handleIncreaseCartItem(product: Product, qty: number) {
    if (!product || !qty || qty === 0)
      return;
    let cart: Product[] = this.getCart();

    if (qty > 0) {
      cart = CartCalc.increaseQuantity(product, cart, qty);
    } else { //<0
      cart = CartCalc.decreaseQuantity(product, cart, -qty);
    }

    this.setState({ cart });
  }

  addProductToCart(product: Product) {
    let cart: Product[] = this.getCart();
    cart = CartCalc.addProduct(product, cart);
    this.setState({ cart });
  }

  addMenuToCart(menu: Product) {
    let cart: Product[] = this.getCart();
    cart = CartCalc.addMenu(menu, cart);
    this.setState({ cart });
  }

  getCart(): Product[] {
    return JSON.parse(JSON.stringify(this.state.cart));
  }

  componentDidMount() {
    this.eventIDs = [];
    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({ language, selectedShop, categories, products, orders, storages, shopProfile, showCommentsInOrdering }) => {
        if (language !== undefined)
          this.setState({ language });
        if (showCommentsInOrdering !== undefined || shopProfile !== undefined || selectedShop !== undefined || categories !== undefined || products !== undefined || orders !== undefined || storages !== undefined)
          this.loadContext();
      },
    );
    this.eventIDs.push(eid);

    this.loadProps();
    this.loadContext();
    this.selectDefaultCategory();
  }

  addView() {
    EventSystem.publish(EventSystem.events.edit_view);
  }

  clickView(e: MouseEvent<HTMLDivElement>, category: Category) {
    if (!category)
      return;

    let productIDs: ProductView[] = ContextSystem.productViews.filter(pv => pv.categoryID === category.id);
    EventSystem.publish(EventSystem.events.edit_view, { view: category, productIDs });
  }

  saveDefaultCategory(e: MouseEvent<HTMLDivElement>, category: Category) {
    if(category === undefined)
      category = -1;

    let id = category?.id ?? category;

    window.localStorage.setItem("defaultCategory", id);
    this.setState({ defaultCategory: id });
    toast(Language.getName(Names.DefaultCategorySaved));
  }

  selectCategory(category: Category | undefined) {
    let selectedCategory = category;
    if (category == null || (category !== -1 && this.state.selectedCategory && this.state.selectedCategory?.id === category.id))
      selectedCategory = undefined;

    //go up one in the category tree
    if (category && this.state.selectedCategory && this.state?.selectedCategory?.id === category?.id && this.state?.selectedCategory?.parent > 0)
      selectedCategory = this.state.categories.find(c => c.id === this.state.selectedCategory.parent);

    this.setState({ selectedCategory });
  }

  filterProduct(p: Product): boolean {
    if (!p)
      return false;
    if (!p.enabled || (!p.availablePOS && this.state.mode !== ProductRenderModes.HIDE_UNHIDE))
      return false;
    if (this.state.selectedCategory === -1 && (p.type !== ProductType.MENU || p.menuSelected !== true))
      return false;
    else if (!!this.state.selectedCategory && this.state.selectedCategory !== -1) {
      if (this.state.selectedCategory?.view === CategoryViewTypes.PRIVATE_VIEW) {
        if (!this.state.productViews.find(pv => pv.enabled && pv.categoryID === this.state.selectedCategory?.id && pv.productID === p.id))
          return false;
      } else {
        if (p.categoryID !== this.state.selectedCategory.id) {
          let foundInChildren: boolean = false;
          for (const childCat of this.state.categories) {
            if (childCat.parent === this.state.selectedCategory.id && p.categoryID === childCat.id) {
              foundInChildren = true;
              break;
            }
          }
          if (!foundInChildren)
            return false;
        }
      }
    }

    if (this.state.search?.length > 0) {
      let s: string = this.state.search.toLowerCase();
      if (TranslatableString.get(p.name).toLowerCase().includes(s) || TranslatableString.get(p.details).toLowerCase()
        .includes(s))
        return true;

      if (p.products?.length > 0) {
        let innerP = p.products.find(
          (pr) => TranslatableString.get(pr.name).toLowerCase().includes(s) || TranslatableString.get(p.details)
            .includes(s));
        if (innerP)
          return true;
      }
      return false;
    }

    return true;
  }

  removeSelectedCoupon(c: Coupon) {
    if (!c)
      return;

    let selectedCoupons: Coupon[] = this.state.selectedCoupons.filter(cp => cp.id !== c.id);

    this.setState({ selectedCoupons });
  }

  hideProduct(product: Product) {
    ProductsAPI.toggleProductActive(product.id, (res) => {
      if (res.error !== 0)
        return;
      if (product.active)
        toast(Language.getName(Names.ProductHiddenText));
      else
        toast(Language.getName(Names.ProductUnhiddenText));
    });
  }

  handleProductClick(product: Product, edit: boolean = false) {
    if (this.state.mode === ProductRenderModes.ORDER) {
      this.addProductOrMenuToCartClicked(product, edit);
    } else if (this.state.mode === ProductRenderModes.HIDE_UNHIDE) {
      this.hideProduct(product);
    }
  }

  addProductOrMenuToCartClicked(product: Product, edit: boolean = false) {
    if (!product || !product.active)
      return;

    let isDisabled: boolean = false;
    if (product?.weekSchedule && product.weekSchedule.length > 0) {
      let openStatus = getOpeningState(product.weekSchedule);
      isDisabled = openStatus.status !== HourStatuses.OPEN && openStatus.status !== HourStatuses.NO_DATA;
    }

    if (isDisabled) {
      toast(Language.getName(Names.ProductNotAvailableNow));
      return;
    }

    product = JSON.parse(JSON.stringify(product));
    let productToDelete: Product = JSON.parse(JSON.stringify(product));

    if (product.extraGroups.length > 0 || product.versions.length > 0) {
      EventSystem.publish(EventSystem.events.open_extras_selector, {
        product, onFinish: (p: Product) => {
          if (edit) {
            let leadOP: OrderProduct = CartCalc.getLeadOrderProduct(p.orderProduct);
            let leadOPDelete: OrderProduct = CartCalc.getLeadOrderProduct(productToDelete.orderProduct);

            leadOP.qty = leadOPDelete.qty;

            let cart: Product[] = this.getCart();
            cart = CartCalc.removeProduct(productToDelete, cart);

            if (p.type === ProductTypes.PRODUCT)
              cart = CartCalc.addProduct(p, cart);
            else if (p.type === ProductTypes.MENU)
              cart = CartCalc.addMenu(p, cart);

            this.setState({ cart });
          } else {
            let leadOP: OrderProduct = CartCalc.getLeadOrderProduct(p.orderProduct);

            leadOP.extrasPrice = CartCalc.getOrderProductExtrasPrice(leadOP, p);
            leadOP.originalExtrasPrice = leadOP.extrasPrice;
            leadOP.itemTotalPrice = leadOP.qty * (leadOP.price + leadOP.extrasPrice);
            leadOP.originalItemTotalPrice = leadOP.itemTotalPrice;
            for (let extra: OrderExtra of leadOP.extras) {
              extra.originalTotal = extra.total;
              extra.originalPrice = extra.price;
            }

            this.addProductOrMenuToCart(p);
          }
        },
      });
    } else {
      if (!edit) {
        let leadOP: OrderProduct = CartCalc.getLeadOrderProduct(product.orderProduct);
        leadOP.itemTotalPrice = leadOP.price;
        leadOP.originalItemTotalPrice = leadOP.itemTotalPrice;
        leadOP.extrasPrice = 0;
        leadOP.originalExtrasPrice = 0;
        this.addProductOrMenuToCart(product);
      }
    }
  }

  didOrderCouponsChange(): boolean {
    if (!this.state.modifyingOrder)
      return false;

    let couponIDs: number[] = this.state.modifyingOrder.usedCoupons.map(uc => uc.usedCouponID).distinct();

    if (couponIDs.length !== this.state.selectedCoupons.length)
      return true;

    for (let cid of couponIDs) {
      if (!this.state.selectedCoupons.find(sc => sc.id === cid))
        return true;
    }
    return false;
  }

  didOrderProductChange(): boolean {
    if (!this.state.modifyingOrder)
      return false;

    let cart: Product[] = this.getCart();

    let originalCart: Product[] = CartCalc.createCartFromOrder(this.state.modifyingOrder);
    for (let product: Product of originalCart) {
      let leadOP: OrderProduct = CartCalc.getLeadOrderProduct(product.orderProduct);
      cart = CartCalc.decreaseQuantity(product, cart, leadOP.qty, true);
    }

    for (let product of cart) {
      let leadOP: OrderProduct = CartCalc.getLeadOrderProduct(product.orderProduct);
      if (leadOP.qty !== 0)
        return true;
    }

    return false;
  }

  setSearch(search: string) {
    this.setState({ search });
    this.loadContext();
  }

  sendOrderFinalStep() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, timeout: true });
    }, 10_000);

    let cart: Product[] = this.getCart();

    //we need to calculate here the difference between current modified order (cart) and original modifying order
    if (this.state.modifyingOrder) {
      let originalCart: Product[] = CartCalc.createCartFromOrder(this.state.modifyingOrder);
      let couponsChange: boolean = this.didOrderCouponsChange();
      for (let product: Product of originalCart) {
        let leadOP: OrderProduct = CartCalc.getLeadOrderProduct(product.orderProduct);
        if (couponsChange) {
          leadOP.qty = -leadOP.qty;
          CartCalc.addProductToCart(product, cart);
        } else
          cart = CartCalc.decreaseQuantity(product, cart, leadOP.qty, true);

        //decreaseQuantity vs addProductToCart:
        //decreaseQuantity will start checking if there is a matching product in the cart
        //addProductToCart will not check for matching products, it will add it to the end of the list
      }
    }

    let order: Order = CartCalc.createOrderFromState(cart, this.state.newPayments, this.state.tableSelected,
      this.state.modifyingOrder?.number ?? undefined, this.state.selectedCoupons, this.state.profile ?? null,
      this.state.orderComment, this.state.shippingMethod,
    );

    OrdersAPI.sendOrder(order, true, cart, ContextSystem.selectedCounter, (res: SendOrderMessageType) => {
      this.setState({ loading: false });

      if (res.error !== ErrorMessage.OK) {
        this.doneClicked = false;
        this.setState(({ doneClicked: false }));
        return;
      }

      let savedOrder: Order = res.order;

      let payments: OrderPayment[] = this.state.newPayments;
      let orderPaymentsSend: PutPaymentType[] = payments.map(op => {
        op.productPayments.forEach(opp => {
          let cartElement: Product = cart.find(p => p.id === opp.orderProductID);
          let savedOrderProductElement: OrderProduct = savedOrder.productList.find(
            orderProd => orderProd.productID = opp.orderProductID);

          if (!cartElement || !savedOrderProductElement)
            return;

          opp.orderProductID = savedOrderProductElement.id;
        });
        return { orderNumber: savedOrder.number, orderPayment: op };
      });

      ContextSystem.mergeProducts(res.products);

      //payment is processed automatically if it is a first order, not a modification
      if (!this.state.modifyingOrder || !orderPaymentsSend || orderPaymentsSend.length <= 0) {
        this.done();
        return;
      }

      OrdersAPI.putPayments(orderPaymentsSend, [], res2 => {
        if (res2.error !== ErrorMessage.OK) {
          toast(Language.getName(Names.OrderPaymentErrorAfterOrder) + " - " + savedOrder.number);
          this.doneClicked = false;
          this.setState(({ doneClicked: false }));
          return;
        }

        this.done();
      });
    });
  }

  done() {
    this.clearCart(true);
    this.clearPayment();
    this.setSearch("");
    this.setState({
      orderComment: "",
      selectedCoupons: [],
    });
    this.selectCategory(undefined);
    this.props.onDone && this.props.onDone();
    this.doneClicked = false;
    this.setState(({ doneClicked: false }));
    this.selectDefaultCategory();
  }

  clearPayment() {
    this.setState({ newPayments: [] });
  }

  async selectDefaultCategory(recursive: number = 0) {
    let defaultCategory: number = this.getDefaultCategory();
    if (defaultCategory === -1)
      return;

    while (ContextSystem.categories === undefined || ContextSystem.categories.length <= 0) {
      await ContextSystem.sleep(250);
      console.log("Categories still not loaded");
    }

    let cat = ContextSystem.categories.find(c => c.id === defaultCategory);
    if (!cat) {
      if (recursive < 10) {
        await ContextSystem.sleep(250);
        console.log("Default category not found. Trying again.");
        await this.selectDefaultCategory(recursive + 1);
        return;
      }
      console.log("Default category not found.");
      return;
    }

    this.setState({ selectedCategory: cat });
  }

  doneClicked: boolean = false;

  handleDoneClick() {
    if (this.doneClicked)
      return;
    this.doneClicked = true;
    this.setState(({ doneClicked: true }));

    // if (this.state.mode === Modes.ORDER) {
    this.sendOrderInitialStep();
    // } else if (this.state.mode === Modes.HIDE_UNHIDE) {
    //   //nothing to do here
    // }
  }

  sendOrderInitialStep(): void {
    // steps should be as below:
    //   1) sendOrderInitialStep -> 2) sendOrderPaymentStep -> 3) sendOrderFinalStep
    // it should be able to be cancelled on any step before final step

    if (!this.state.cart || this.state.cart.length <= 0) {
      if (this.state.modifyingOrder) {
        toast(Language.getName(Names.DeleteOrderInsteadOfModify));
      } else {
        this.props.onDone && this.props.onDone();
      }
      this.doneClicked = false;
      this.setState(({ doneClicked: false }));
      return;
    }
    if (this.state.modifyingOrder && !this.didOrderProductChange()) {
      this.props.onDone && this.props.onDone();
      this.doneClicked = false;
      this.setState(({ doneClicked: false }));
      return;
    }

    //pop-up window will not open itself if it has no use (disabled, not needed... etc.)
    //and will return on the callback without any change to the passed objects (cart, table, shippingMethod...)
    EventSystem.publish(EventSystem.events.open_table_selector, {
      tableSelected: this.state.tableSelected,
      cart: this.getCart(),
      modifyingOrder: this.state.modifyingOrder,
      shippingMethod: this.state.shippingMethod,
      origin: this.state.origin, //orderEditor or undefined (StandingLayout)
      doneClicked: true,
      cb: ({ tableSelected, shippingMethod, cancelled, address, cart }) => {
        if (cancelled) {
          this.doneClicked = false;
          this.setState(({ doneClicked: false }));
        } else {
          this.setState({ tableSelected, shippingMethod, address, cart }, () => {
            this.sendOrderPaymentStep();
          });
        }
      },
    });
  }

  sendOrderPaymentStep() {
    let existingPayments: OrderPayment[] = [];
    if (this.state.modifyingOrder)
      this.state.modifyingOrder.payments.forEach(op => existingPayments.push({ ...op }));

    let cart = this.getCart();
    // only for cart coupons' calculation
    CartCalc.createOrderFromState(cart, this.state.newPayments, this.state.tableSelected,
      this.state.modifyingOrder?.number ?? undefined, this.state.selectedCoupons, this.state.profile ?? null,
      this.state.orderComment,
    );

    //pop-up window will not open itself if it has no use (disabled, not needed... etc.)
    //and will return on the callback without any change to the 'payment' object

    //existingPayments, newPayments, cart, cb, force
    EventSystem.publish(EventSystem.events.open_order_payment, {
      existingPayments,
      newPayments: this.state.newPayments,
      showBackButton: true,
      cart,
      cb: ({ newPayments, deletePayments, cancelled, back }) => {
        if (back === true) {
          this.sendOrderInitialStep();
        } else if (cancelled === true) {
          this.doneClicked = false;
          this.setState(({ doneClicked: false }));
        } else {
          this.setState({ newPayments, deletePayments }, () => {
            this.sendOrderFinalStep();
          });
        }
      },
    });
  }

  reset() {
    let disabled: boolean = !this.didOrderProductChange();

    if (disabled)
      return;

    ConfirmationModal.showModal(
      Language.getName(Names.Sure),
      Language.getName(Names.ResetOriginalOrderText),
      Language.getName(Names.Yes),
      Language.getName(Names.CancelButtonText),
      () => {
      },
      () => {
        this.editOrder(this.state.modifyingOrder);
      },
    );
  }

  clearCart(quite: boolean = false) {
    if (this.state.cart.length <= 0)
      return;

    if (quite) {
      this.setState({ cart: [] });
      return;
    }

    ConfirmationModal.showModal(
      Language.getName(Names.Sure),
      Language.getName(Names.ClearCartConfirmationText),
      Language.getName(Names.Yes),
      Language.getName(Names.CancelButtonText),
      () => {
      },
      () => {
        this.setState({ cart: [] });
      },
    );
  }

  static createOrderPayments(payment: { [keys: number]: number }): OrderPayment[] {
    let orderPayments: OrderPayment[] = [];
    for (let key of Object.keys(payment)) {
      if (!key || !payment[key])
        continue;
      let value: number = parseFloat(payment[key]);
      if (isNaN(value) || value === 0)
        continue;

      orderPayments.push({
        id: -1,
        enabled: true,
        orderID: -1,
        txid: -1,
        creditedAmount: 0,
        paymentType: key,
        payed: true,
        amount: value,
        orderContactID: -1,
        productPayments: [],
      });
    }
    return orderPayments;
  }

  selectCustomer(profileEditable: boolean): void {
    if (!profileEditable)
      return;

    EventSystem.publish(EventSystem.events.add_new_customer, {
      closeOnSelected: true,
      cb: (profile: Profile) => {
        this.setState({ profile });
      },
    });
  }

  getNotConfirmedOrdersNumber(): number {
    let allowedTypes: number[] = [ShippingMethods.DELIVERY, ShippingMethods.VENDOR_DELIVERY, ShippingMethods.PICKUP];
    if (this.state.selectedShop.posStandShowLocalOrders)
      allowedTypes.push(ShippingMethods.AT_PLACE);

    return this.state.orders.filter(o =>
      o.lastState?.status === OrderState.NEW
      && allowedTypes.includes(o.shippingMethod),
    ).length;
  }

  handleOrdersClicked() {
    EventSystem.publish(EventSystem.events.open_orders_modal);
  }

  startHideFunction(): void {
    this.setState({
      mode: (this.state.mode === ProductRenderModes.HIDE_UNHIDE
             ? ProductRenderModes.ORDER
             : ProductRenderModes.HIDE_UNHIDE),
    });
  }

  openCouponsModal(): void {
    EventSystem.publish(EventSystem.events.open_coupons_modal,
      {
        selectedCoupons: this.state.selectedCoupons,
        cb: ({ selectedCoupons }) => {
          this.setUsedCoupons(selectedCoupons);
        },
      },
    );
  }

  setUsedCoupons(selectedCoupons: Coupon[]) {
    //get affected products from modifying order
    //if (affected products is not empty) {
    //remove affected products from cart
    //add them again
    //}
    this.setState({ selectedCoupons });
  }

  static renderProduct(
    p: Product, filterProduct: ()=>boolean, i: number, mode: ProductRenderMode, renderHideFunction: boolean,
    onClick: ()=>{}, renderQtyEditor: boolean = false, increaseClick: ()=>{}, qty: number = 0,
  ) {
    if (filterProduct && !filterProduct(p))
      return <React.Fragment key={i} />;

    // let price: number = p.price + (p.versions[0]?.price ?? 0);
    // let priceS: string = price + " " + "Ft";

    let isDisabled: boolean = false;
    if (p?.weekSchedule && p.weekSchedule.length > 0) {
      let openStatus = getOpeningState(p.weekSchedule);
      isDisabled = openStatus.status !== HourStatuses.OPEN && openStatus.status !== HourStatuses.NO_DATA;
    }

    return (
      <ProductDiv is_disabled={isDisabled} key={i} onClick={() => onClick && onClick(p)}>
        <ProductImage onError={(e) => (e.target.style.visibility = "hidden")}
                      src={Config.productImageUrlBase + p.image} />
        <ProductDetails>
          {(mode === ProductRenderModes.ORDER || !mode) &&
            <div>
              {!p.active &&
                <FaEyeSlash />
              }
              <b>{TranslatableString.get(p.name)}</b>
            </div>
          }
          {renderHideFunction &&
            <>
              {mode === ProductRenderModes.HIDE_UNHIDE &&
                <>
                  <b>{TranslatableString.get(p.name)}</b><br />
                  <HideUnhideButton>
                    {p.active &&
                      <><FaEye /> {Language.getName(Names.Hide)}</>
                    }
                    {!p.active &&
                      <><FaEyeSlash /> {Language.getName(Names.Unhide)}</>
                    }
                  </HideUnhideButton>
                </>
              }
            </>
          }
          {renderQtyEditor &&
            <ProductQtyEditor>
              <ProductPlusMinusButton
                onClick={(e) => {
                  e.stopPropagation();
                  increaseClick && increaseClick(p, -1);
                }}
                margin_right={"0"}
                margin_left={"0"}
              >
                {"-"}
              </ProductPlusMinusButton>
              <ProductQtyDiv>
                {qty}
              </ProductQtyDiv>
              <ProductPlusMinusButton
                onClick={(e) => {
                  e.stopPropagation();
                  increaseClick && increaseClick(p, +1);
                }}
                margin_right={"0"}
                margin_left={"0"}
              >
                {"+"}
              </ProductPlusMinusButton>
            </ProductQtyEditor>
          }
        </ProductDetails>
      </ProductDiv>
    );
  }

  render() {
    let selectedCategory: Category = this.state.selectedCategory;
    if (selectedCategory && (selectedCategory !== -1 && selectedCategory.parent !== 0)) {
      selectedCategory = this.state.categories.find(cat => cat.id === selectedCategory.parent);
    }

    let cart: Product[] = this.getCart();
    let order: Order = CartCalc.createOrderFromState(cart, this.state.newPayments, this.state.tableSelected,
      this.state.modifyingOrder?.number ?? undefined, this.state.selectedCoupons, this.state.profile ?? null,
      this.state.orderComment,
    );

    let total: number = order?.orderTotalPrice ?? 0;
    let totalPayed: number = 0;
    if (this.state.modifyingOrder) {
      this.state.modifyingOrder.payments.forEach(op => totalPayed += op.amount);
    }

    if (!!order)
      order.payments.forEach(op => totalPayed += op.amount);
    // let leftForTotal: number = total - totalPayed;

    let profileEditable: boolean = true;
    let profile: Profile = this.state.profile;

    if (this.state.modifyingOrder) {
      profile = this.state.modifyingOrder.profile;
      profileEditable = false;
    }
    if (this.state.tableSelected) {
      let res: TableReservation = ContextSystem.getTableReservation(this.state.tableSelected.id,
        ContextSystem.tableReservations,
      );

      if (res && res.profileID > 0) {
        let pr: Profile = ContextSystem.profiles.find(prof => prof.id === res.profileID);
        if (pr) {
          profile = pr;
          profileEditable = false;
        }
      }
    }

    let products: Product[] = this.state.products;

    let categoriesInsideSelectedCat: number = 0;
    if(selectedCategory !== undefined && selectedCategory !== -1)
      categoriesInsideSelectedCat = this.state.categories.filter(c => c.parent === selectedCategory.id).length;

    let showMainCategories: boolean = selectedCategory === undefined || categoriesInsideSelectedCat <= 0;

    return (
      <Wrapper padding={this.state.padding}>
        <RowWrapper grow={true} height={"80%"}>
          <MenuContainer>
            <TopWrapper>
              <RowWrapper height={"100%"} justify_content={"space-between"}>
                <CategoriesWrapper>
                  {showMainCategories &&
                    <CategoriesScroll>
                      <List scroll={true}>
                        {(() => {
                          let itemsInside: number = products.filter(p => p.type === ProductType.MENU && p.enabled && p.menuSelected).length;
                          if (itemsInside <= 0)
                            return <></>;

                          return (
                            <CategoryDiv selected={this.state.selectedCategory === -1}
                                         max_width={"unset"}
                                         onClick={() => this.selectCategory(this.state.selectedCategory === -1 ? undefined : -1)}
                            >
                              <div>{Language.getName(Names.SpecialOffer)}</div>
                              <div>({itemsInside})</div>
                            </CategoryDiv>
                          );
                        })()}
                        {this.state.categories.map((cat, i) => {
                            if (!cat || !cat.enabled)
                              return <React.Fragment key={i} />;
                            if (cat.parent !== 0)
                              return <React.Fragment key={i} />;

                            let itemsInside: number;

                            if (cat.view === CategoryViewTypes.NORMAL_CATEGORY) {
                              if (cat?.parent === 0)
                                itemsInside = this.state.categories.filter(c => c.parent === cat.id).length;
                              if (itemsInside <= 0 || !(cat?.parent === 0))
                                itemsInside = products.filter(p => p.categoryID === cat.id && p.enabled).length;
                            } else {
                              itemsInside = this.state.productViews.filter(pv => pv.enabled && pv.categoryID === cat.id).length;
                            }

                            //if (itemsInside <= 0)
                            //return <React.Fragment key={i} />;

                            return (
                              <CategoryDiv key={i}
                                           max_width={"unset"}
                                           selected={this.state.selectedCategory?.id === cat.id || this.state.selectedCategory?.parent === cat.id}
                                           onClick={() => this.selectCategory(cat)}
                              >
                                <div>{TranslatableString.get(cat.name)}</div>
                                <div>({itemsInside})</div>
                              </CategoryDiv>
                            );
                          },
                        )}
                        <CategoryDiv onClick={() => this.addView()}>
                          <div><BsPlus style={{ fontWeight: "bold" }} /></div>
                        </CategoryDiv>
                        <CategoryDiv onClick={e => this.saveDefaultCategory(e, this.state.selectedCategory)}>
                          {this.state.defaultCategory === (this.state.selectedCategory === undefined ? -1 : this.state.selectedCategory.id) &&
                            <div><FaSave /></div>
                          }
                          {this.state.defaultCategory !== (this.state.selectedCategory === undefined ? -1 : this.state.selectedCategory.id) &&
                            <div><FaRegSave /></div>
                          }
                        </CategoryDiv>
                      </List>
                    </CategoriesScroll>
                  }
                  {!showMainCategories &&
                    <CategoriesScroll>
                      <List scroll={true}>
                        <CategoryDiv min_height={"43px"} onClick={() => this.selectCategory(undefined)}>
                          <div>{" < " + Language.getName(Names.Back)}</div>
                        </CategoryDiv>
                        {this.state.selectedCategory?.view === CategoryViewTypes.PRIVATE_VIEW &&
                          <CategoryDiv onClick={e => this.clickView(e, this.state.selectedCategory)}>
                            <div><BsPencilSquare /></div>
                          </CategoryDiv>
                        }

                        {this.state.categories.map((cat, i) => {
                            if (!cat || !cat.enabled)
                              return <React.Fragment key={i} />;
                            if (!selectedCategory)
                              return <React.Fragment key={i} />;
                            if (selectedCategory === -1)
                              return <React.Fragment key={i} />;
                            else if (cat.parent !== selectedCategory.id)
                              return <React.Fragment key={i} />;

                            if (this.state.selectedCategory.parent === 0) {
                              if (cat.parent !== this.state.selectedCategory.id) //my parent
                                return <React.Fragment key={i} />;
                            } else {
                              if (cat.parent !== this.state.selectedCategory.parent) //same parent
                                return <React.Fragment key={i} />;
                            }

                            let itemsInside: number = cat?.parent === 0 ? this.state.categories.filter(c => c.parent === cat.id).length : 0;
                            if (itemsInside <= 0 || !(cat?.parent === 0)) {
                              itemsInside = products.filter(p => p.categoryID === cat.id && p.enabled && p.active).length;
                            }

                            if (itemsInside <= 0)
                              return <React.Fragment key={i} />;

                            return (
                              <CategoryDiv key={i}
                                           onClick={() => this.selectCategory(cat)}
                                           selected={this.state.selectedCategory?.id === cat.id}
                              >
                                <div>{TranslatableString.get(cat.name)}</div>
                                <div>({itemsInside})</div>
                              </CategoryDiv>
                            );
                          },
                        )}

                        <CategoryDiv onClick={e => this.saveDefaultCategory(e, this.state.selectedCategory)}>
                          {this.state.defaultCategory === this.state.selectedCategory?.id &&
                            <div><FaSave /></div>
                          }
                          {this.state.defaultCategory !== this.state.selectedCategory?.id &&
                            <div><FaRegSave /></div>
                          }
                        </CategoryDiv>
                      </List>
                    </CategoriesScroll>
                  }
                </CategoriesWrapper>
                <FiltersLeft>
                  <FiltersWrapper>
                    <Input
                      width={"100%"}
                      height={"30px"}
                      margin={"0 5px 0 0"}
                      autoComplete={"off"}
                      value={this.state.search}
                      type="text"
                      placeholder={Language.getName(Names.Search) + "..."}
                      onKeyUp={e => e.key === "Escape" && this.setSearch("")}
                      onChange={(e) => this.setSearch(e.target.value)}
                    />
                    {this.state.search && this.state.search.length > 0 &&
                      <ClearSearchWrapper>
                        <FaTimes onClick={() => this.setSearch("")} />
                      </ClearSearchWrapper>
                    }
                  </FiltersWrapper>
                </FiltersLeft>
              </RowWrapper>
            </TopWrapper>
            <ProductsWrapper>
              <ScrollWrapper vertical={true}>
                <List>
                  {products.map((p, i) => {
                    return StandingComponent.renderProduct(p, p => this.filterProduct(p), i, this.state.mode, true,
                      () => this.handleProductClick(p, false),
                    );
                  })}
                </List>
              </ScrollWrapper>
            </ProductsWrapper>
          </MenuContainer>
          <RightMenu>
            {this.state.modifyingOrder &&
              <ModifyingOrderNumber>
                {Language.getName(Names.OrderIDText) + ": "}
                <span>{this.state.modifyingOrder.number}</span>
                <AiOutlineEdit />
              </ModifyingOrderNumber>
            }
            <CartContentWrapper>
              <CartContent
                showModificationButtons={true}
                products={cart}
                showShippingPrices={true}
                showTotal={false}
                increaseItem={(p: Product, qty: number) => this.handleIncreaseCartItem(p, qty)}
                removeItem={(p: Product) => this.handleRemoveItem(p)}
                onProductClicked={(p) => this.handleProductClick(p, true)}
                fontSize={undefined}
                showPrices={true}
                showOriginalPrice={true}
                showSplitPrice={false}
                setNote={(p: Product, value: string) => this.setNote(p, value)}
                showNote={this.state.showCommentsInOrdering === 1}
              />
            </CartContentWrapper>
            {this.state.showCommentsInOrdering === 1 &&
              <OrderCommentWrapper>
                <Textarea
                  placeholder={Language.getName(Names.Comment) + "..."}
                  value={this.state.orderComment}
                  onChange={e => this.setState({ orderComment: e.target.value })}
                />
              </OrderCommentWrapper>
            }
            <TotalDiv>
              {order?._appliedCoupons?.length > 0 &&
                <>
                  <TotalRow color={"rgb(151,151,151)"} font_size={"14pt"}>
                    <span>{Language.getName(Names.Subtotal)}</span>
                    <span>{(order?.originalOrderTotalPrice ?? 0).toLocaleString()} Ft</span>
                  </TotalRow>
                  {order?._appliedCoupons?.map((c: Coupon, i: number) => {
                      return (
                        <TotalRow key={i} color={"rgb(151,151,151)"} font_size={"14pt"}>
                          <span>{TranslatableString.get(
                            c.name)} ({-c.couponValue > 0 && "+"}{(-c.couponValue).toLocaleString()}
                            {c.type === CouponTypes.FIX ? "Ft" : "%"})
                          </span>
                          <span onClick={() => this.removeSelectedCoupon(c)}>
                            <FaTrashAlt />
                          </span>
                        </TotalRow>
                      );
                    },
                  )}
                </>
              }
              <TotalRow font_size={"16pt"}>
                <span>{Language.getName(Names.Total)}</span>
                <span>{Math.round(total).toLocaleString()} Ft</span>
              </TotalRow>
            </TotalDiv>
          </RightMenu>
        </RowWrapper>
        <RowWrapper height={"fit-content"} m_height={"fit-content"}>
          <ControlsWrapper>
            <ActionButtons>
              <ActionsLeft>
                <Button onClick={() => this.selectCustomer(profileEditable)}
                        bg_color={!profileEditable ? "rgb(199,199,199)" : "rgb(86,112,164)"}
                        bg_color2={!profileEditable ? "rgb(199,199,199)" : "rgb(111,144,212)"}
                        bg_color3={!profileEditable ? "rgb(199,199,199)" : "rgb(69,95,146)"}
                        color={!profileEditable ? "rgb(128,128,128)" : "white"}
                        color2={!profileEditable ? "rgb(128,128,128)" : "white"}
                        color3={!profileEditable ? "rgb(128,128,128)" : "white"}
                        border_color={!profileEditable ? "rgb(128,128,128)" : "rgb(111,144,212)"}
                        width={"100px"}
                        m_width={"80px"}
                >
                  {profile &&
                    <>
                      {profile.firstName}
                    </>
                  }
                  {!profile &&
                    <>
                      {Language.getName(Names.Customer)}
                    </>
                  }
                </Button>
                <Button onClick={() => this.openCouponsModal()}
                        bg_color={"rgb(86,112,164)"}
                        bg_color2={"rgb(111,144,212)"}
                        bg_color3={"rgb(69,95,146)"}
                        color={"white"}
                        border_color={"rgb(86,112,164)"}
                        width={"110px"}
                        m_width={"95px"}
                >
                  {Language.getName(Names.PriceAdjustments)}
                </Button>
                {this.state.shopProfile?.permissions[this.state.selectedShop?.id]?.includes(
                    ShopProfileTypes.MODIFY_MENU) &&
                  <Button onClick={() => this.startHideFunction()}
                          bg_color={"rgb(86,112,164)"}
                          bg_color2={"rgb(111,144,212)"}
                          bg_color3={"rgb(69,95,146)"}
                          color={"white"}
                          border_color={"rgb(86,112,164)"}
                          width={"110px"}
                          m_width={"95px"}
                  >
                    {Language.getName(Names.Hide)}
                  </Button>
                }
              </ActionsLeft>
              <OrderCommentsSwitchWrapper>
                {this.state.showCommentsInOrdering === 1 &&
                  <FaRegCommentDots
                    onClick={() => ContextSystem.setShowCommentsInOrdering(0)}
                  />
                }
                {this.state.showCommentsInOrdering !== 1 &&
                  <FaCommentDots
                    onClick={() => ContextSystem.setShowCommentsInOrdering(1)}
                  />
                }
              </OrderCommentsSwitchWrapper>
              {this.state.showOrdersButton &&
                <Button onClick={() => this.handleOrdersClicked()}
                        bg_color={"rgb(175,9,112)"}
                        bg_color2={"rgb(208,15,138)"}
                        bg_color3={"rgb(139,8,88)"}
                        color={"white"}
                        border_color={"rgb(86,112,164)"}
                        width={"100px"}
                        m_width={"80px"}
                >
                  {(() => {
                    let newOrders: number = this.getNotConfirmedOrdersNumber();
                    if (newOrders <= 0)
                      return <></>;
                    else
                      return (
                        <ButtonTag
                          width={"18px"}
                          height={"18px"}
                          top={"-7px"}
                          right={"-7px"}
                        >
                          {this.getNotConfirmedOrdersNumber()}
                        </ButtonTag>
                      );
                  })()}
                  {Language.getName(Names.Orders)}
                </Button>
              }
              {this.state.origin === "orderEditor" && this.state.storages.filter(
                  st => st.type === StorageTypes.COUNTER).length > 0 &&
                <CounterSelect
                  placeholder={Language.getName(Names.UsedStorage)}
                  value={this.state.selectedCounter}
                  onChange={(e) => ContextSystem.selectCounter(e.value)}
                  options={this.state.storages.filter(st => st.type === StorageTypes.COUNTER).map(st => ({
                    value: st.id,
                    label: st.name,
                  }))}
                />
              }
              {this.state.modifyingOrder && (() => {
                let disabled: boolean = !this.didOrderProductChange();
                return (
                  <Button onClick={() => this.reset()}
                          bg_color={disabled ? "rgb(199,199,199)" : "rgb(226,97,11)"}
                          bg_color2={disabled ? "rgb(199,199,199)" : "rgb(255,103,2)"}
                          bg_color3={disabled ? "rgb(199,199,199)" : "rgb(198,53,13)"}
                          color={disabled ? "rgb(128,128,128)" : "white"}
                          color2={disabled ? "rgb(128,128,128)" : "white"}
                          color3={disabled ? "rgb(128,128,128)" : "white"}
                          border_color={disabled ? "rgb(128,128,128)" : "rgb(226,97,11)"}
                  >
                    {Language.getName(Names.Reset)}
                  </Button>
                );
              })()
              }
              <Button onClick={() => this.clearCart()}
                      bg_color={"rgb(165,13,13)"}
                      bg_color2={"rgb(227,5,5)"}
                      bg_color3={"rgb(130,15,15)"}
                      color={"white"}
                      border_color={"rgb(165,13,13)"}
              >
                {Language.getName(Names.Clear)}
              </Button>
              <Button onClick={() => this.handleDoneClick(true)}
                      bg_color={"rgb(44,165,13)"}
                      bg_color2={"rgb(5,227,5)"}
                      bg_color3={"rgb(15,130,15)"}
                      color={"white"}
                      border_color={"rgb(44,165,13)"}
                      width={"100px"}
                      m_width={"80px"}
                      disabled={this.state.doneClicked}
              >
                {Language.getName(Names.Done)}
              </Button>
            </ActionButtons>
          </ControlsWrapper>
        </RowWrapper>
      </Wrapper>
    );
  }
}
